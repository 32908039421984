import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/select';
import usePharmacyGroups from '@/hooks/usePharmacyGroups';
import { pharmacySchema } from '@/schema/pharmacySchema';
import { IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { Checkbox } from '../ui/checkbox';

type PharmacyFormData = z.infer<typeof pharmacySchema>;

interface PharmacyFormProps {
  pharmacy: IPharmacy;
}

const PharmacyForm: React.FC<PharmacyFormProps> = ({ pharmacy }) => {
  const pharmacyGroups = usePharmacyGroups();
  const queryClient = useQueryClient();

  const form = useForm<PharmacyFormData>({
    resolver: zodResolver(pharmacySchema),
    defaultValues: {
      phone: '',
      name: '',
      icon_url: '',
      timezone: '',
      pharmacy_group_id: '',
      open_time: '09:00 AM',
      close_time: '05:00 PM',
      open_weekend_time: '09:00 AM',
      closed_weekend_time: '03:00 PM',
      open_weekends: true,
      do_not_ai_call: false,
      invalid_pharmacy: false,
      cutoff_time: '03:00 PM',
      lunch_start_time: '01:30 PM',
      lunch_end_time: '02:00 PM',
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = form;

  const openWeekends = watch('open_weekends');

  useEffect(() => {
    if (pharmacy && pharmacy.id) {
      setValue('name', pharmacy.name || '');
      setValue('phone', formatPhoneNumber(pharmacy.phone) || '');
      setValue('icon_url', pharmacy.icon_url || '');
      setValue('timezone', pharmacy.timezone || '');
      setValue('pharmacy_group_id', pharmacy.pharmacy_group_id || '');
      setValue('open_time', formatTime(pharmacy.open_time) || '09:00 AM');
      setValue('close_time', formatTime(pharmacy.close_time) || '05:00 PM');
      setValue('open_weekend_time', formatTime(pharmacy.open_weekend_time) || '09:00 AM');
      setValue('closed_weekend_time', formatTime(pharmacy.closed_weekend_time) || '03:00 PM');
      setValue('open_weekends', pharmacy.open_weekends);
      setValue('do_not_ai_call', pharmacy.do_not_ai_call);
      setValue('invalid_pharmacy', pharmacy.invalid_pharmacy);
      setValue('cutoff_time', formatTime(pharmacy.cutoff_time) || '03:00 PM');
      setValue('lunch_start_time', formatTime(pharmacy.lunch_start_time) || '01:30 PM');
      setValue('lunch_end_time', formatTime(pharmacy.lunch_end_time) || '02:00 PM');
    }
  }, [pharmacy, setValue]);

  /**
   * Helper Function to Format Phone Number
   *
   * Converts a 10-digit phone number string into the format (123) 456-7890
   */
  const formatPhoneNumber = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };

  /**
   * Helper Function to Format Time
   *
   * Converts a time string to 12-hour format with AM/PM
   * Ensures no leading zero in the hour component
   */
  const formatTime = (time: string): string => {
    // If time already includes AM/PM, return as is
    if (time.toUpperCase().includes('AM') || time.toUpperCase().includes('PM')) {
      return time;
    }

    // Convert "HH:MM" to "HH:MM AM/PM"
    const [hourStr, minuteStr] = time.split(':');
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedHour = hour.toString().padStart(2, '0');
    return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
  };
  const onSubmit = async (data: PharmacyFormData) => {
    try {
      const payload = {
        ...data,
        phone: data.phone.replace(/\D/g, ''),
        pharmacy_group_id: data.pharmacy_group_id || null,
      };

      const response = await axiosClient.put(`/admin/pharmacies/${pharmacy?.id}`, payload);

      if (response.data) {
        queryClient.setQueryData([`admin/pharmacies/${pharmacy.id}`], response.data);
      }
    } catch (error: any) {
      console.error('Error saving pharmacy:', error);
      if (error.response?.data?.message) {
        alert(
          Array.isArray(error.response.data.message)
            ? error.response.data.message.join('\n')
            : error.response.data.message,
        );
      }
    }
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Basic Information */}
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* Name */}
            <FormField
              control={control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="name">Pharmacy Name</Label>
                  <FormControl>
                    <Input {...field} id="name" placeholder="Enter pharmacy name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Phone */}
            <FormField
              control={control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="phone">Phone Number</Label>
                  <FormControl>
                    <InputMask
                      mask="(999) 999-9999"
                      {...field}
                      id="phone"
                      placeholder="Enter your phone number"
                      onChange={(e: any) => field.onChange(e.target.value)}
                    >
                      {(inputProps: any) => <Input type="tel" {...inputProps} />}
                    </InputMask>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Icon URL */}
            <FormField
              control={control}
              name="icon_url"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="icon_url">Icon URL</Label>
                  <FormControl>
                    <Input {...field} id="icon_url" placeholder="https://example.com/icon.png" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Pharmacy Group */}
            <FormField
              control={control}
              name="pharmacy_group_id"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="pharmacy_group_id">Pharmacy Group</Label>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value} name="pharmacy_group_id">
                      <SelectTrigger>
                        <SelectValue placeholder="Select a pharmacy group" />
                      </SelectTrigger>
                      <SelectContent>
                        {pharmacyGroups?.data?.map((group) => (
                          <SelectItem key={group.id} value={group.id}>
                            {group.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Timezone */}
            <FormField
              control={control}
              name="timezone"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="timezone">Timezone</Label>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value} name="timezone">
                      <SelectTrigger>
                        <SelectValue placeholder="Select timezone" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="America/New_York">America/New_York</SelectItem>
                        <SelectItem value="America/Chicago">America/Chicago</SelectItem>
                        <SelectItem value="America/Denver">America/Denver</SelectItem>
                        <SelectItem value="America/Phoenix">America/Phoenix</SelectItem>
                        <SelectItem value="America/Los_Angeles">America/Los_Angeles</SelectItem>
                        <SelectItem value="Pacific/Honolulu">Pacific/Honolulu</SelectItem>
                        <SelectItem value="America/Anchorage">America/Anchorage</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Operational Settings */}
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* Do Not AI Call */}
            <FormField
              control={control}
              name="do_not_ai_call"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3">
                  <FormControl>
                    <Checkbox id="do_not_ai_call" checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <Label htmlFor="do_not_ai_call">Do Not AI Call</Label>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Invalid Pharmacy */}
            <FormField
              control={control}
              name="invalid_pharmacy"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3">
                  <FormControl>
                    <Checkbox id="invalid_pharmacy" checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <Label htmlFor="invalid_pharmacy">Invalid Pharmacy</Label>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Operating Hours */}
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* Open Time */}
            <FormField
              control={control}
              name="open_time"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="open_time">Open Time</Label>
                  <FormControl>
                    <Input {...field} id="open_time" placeholder="09:00 AM" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Close Time */}
            <FormField
              control={control}
              name="close_time"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="close_time">Close Time</Label>
                  <FormControl>
                    <Input {...field} id="close_time" placeholder="05:00 PM" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Cutoff Time */}
            <FormField
              control={control}
              name="cutoff_time"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="cutoff_time">Cutoff Time</Label>
                  <FormControl>
                    <Input {...field} id="cutoff_time" placeholder="03:00 PM" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Lunch Start Time */}
            <FormField
              control={control}
              name="lunch_start_time"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="lunch_start_time">Lunch Start Time</Label>
                  <FormControl>
                    <Input {...field} id="lunch_start_time" placeholder="01:30 PM" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Lunch End Time */}
            <FormField
              control={control}
              name="lunch_end_time"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="lunch_end_time">Lunch End Time</Label>
                  <FormControl>
                    <Input {...field} id="lunch_end_time" placeholder="02:00 PM" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="">
            {/* Open on Weekends */}
            <FormField
              control={control}
              name="open_weekends"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3">
                  <FormControl>
                    <Checkbox id="open_weekends" checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <Label htmlFor="open_weekends">Open on Weekends</Label>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {/* Weekend Operating Hours (Conditionally Rendered) */}
          {openWeekends && (
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {/* Open Weekend Time */}
              <FormField
                control={control}
                name="open_weekend_time"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="open_weekend_time">Open Weekend Time</Label>
                    <FormControl>
                      <Input {...field} id="open_weekend_time" placeholder="09:00 AM" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Close Weekend Time */}
              <FormField
                control={control}
                name="closed_weekend_time"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="closed_weekend_time">Close Weekend Time</Label>
                    <FormControl>
                      <Input {...field} id="closed_weekend_time" placeholder="03:00 PM" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button type="submit" disabled={isSubmitting} className="w-full md:w-auto">
              {isSubmitting
                ? pharmacy
                  ? 'Updating Pharmacy...'
                  : 'Creating Pharmacy...'
                : pharmacy
                  ? 'Update Pharmacy'
                  : 'Create Pharmacy'}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default PharmacyForm;
