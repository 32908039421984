import Time from '@/components/Time';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ISession } from '@/types';

interface SessionsTableProps {
  sessions: ISession[];
}

export function SessionsTable({ sessions }: SessionsTableProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Sessions ({sessions.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>IP Address</TableHead>
              <TableHead>Logged In</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sessions.map((session) => (
              <TableRow key={session.id}>
                <TableCell className="font-medium">{session.ip_address}</TableCell>
                <TableCell>
                  <Time>{session.created_at}</Time>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
