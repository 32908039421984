import { Navigate } from 'react-router';
import { useAuth } from '../providers/AuthProvider';
import Layout from './Layout';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const authed = useAuth();
  return authed ? <Layout>{children}</Layout> : <Navigate to="/" />;
};

export default PrivateRoute;
