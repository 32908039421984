import { Mail, MessageSquare, Phone, MapPin, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import NeedlePin from '@/assets/logo-wordmark.svg';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { IUser } from '@/types';
import { formatDateMMDDYYYY, formatPhoneNumber } from '@/utils/helpers';

interface ProfileHeaderProps {
  user: IUser;
}

export function ProfileHeader({ user }: ProfileHeaderProps) {
  const hasInitials = user?.first_name?.[0] || user?.last_name?.[0];
  const initials = hasInitials ? `${user?.first_name?.[0] || ''}${user?.last_name?.[0] || ''}`.toUpperCase() : null;
  const avatarUrl = user?.picture || null;

  const latestAddress = user?.locations?.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  )[0];

  return (
    <div className="flex bg-white rounded-lg border p-6">
      {/* Avatar Section */}
      <div className="flex-none">
        <Avatar className="h-16 w-16">
          {avatarUrl ? (
            <AvatarImage src={avatarUrl} alt={user?.first_name ? `${user.first_name} ${user.last_name}` : 'User'} />
          ) : initials ? (
            <AvatarFallback className="bg-primary text-primary-foreground">{initials}</AvatarFallback>
          ) : (
            <AvatarFallback>
              <img src={NeedlePin} alt="Needle Logo" className="p-2" />
            </AvatarFallback>
          )}
        </Avatar>
      </div>

      {/* Info Sections */}
      <div className="flex flex-1 gap-6 ml-6">
        {/* Basic Info */}
        <div className="flex-1 min-w-[200px]">
          <h2 className="text-xl font-semibold mb-2">
            {user?.first_name} {user?.last_name}
          </h2>
          <div className="space-y-1">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Mail className="h-4 w-4 shrink-0" />
              <span className="truncate">{user?.email}</span>
            </div>
            {user?.phone && (
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Phone className="h-4 w-4 shrink-0" />
                <span>{formatPhoneNumber(user.phone)}</span>
              </div>
            )}
          </div>
        </div>

        {/* Additional Details */}
        <div className="flex-1 border-l px-6 min-w-[250px]">
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Calendar className="h-4 w-4 shrink-0" />
              <span>DOB: {user?.dob ? formatDateMMDDYYYY(user.dob) : 'Not Provided'}</span>
            </div>
            {latestAddress && (
              <div className="flex gap-2 text-sm text-muted-foreground">
                <MapPin className="h-4 w-4 shrink-0 mt-1" />
                <div className="break-words">
                  {latestAddress.address}, {latestAddress.city}, {latestAddress.state} {latestAddress.zip}
                </div>
              </div>
            )}
            <div className="text-sm text-muted-foreground">Member since: {formatDateMMDDYYYY(user?.created_at)}</div>
          </div>
        </div>

        {/* Status & Actions */}
        <div className="flex-1 flex flex-col items-end gap-2 min-w-[200px]">
          {user?.membership_is_active ? (
            <>
              <Badge>Membership: {user?.plan?.toLocaleUpperCase()}</Badge>
              <Badge variant="outline">
                {formatDateMMDDYYYY(user?.plan_start_at)} - {formatDateMMDDYYYY(user?.plan_end_at)}
              </Badge>
            </>
          ) : (
            <Badge variant="destructive">Membership Inactive</Badge>
          )}
          {user?.medical_provider ? (
            <Badge variant="in_stock">Medical Provider</Badge>
          ) : (
            <Badge variant="destructive">NON Medical Provider</Badge>
          )}
          <Button variant="outline" size="sm" asChild className="mt-2">
            <Link to={`/messages/${user?.id}`} className="gap-2">
              <MessageSquare className="h-4 w-4" />
              Text Messages
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
