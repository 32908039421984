import { Link } from 'react-router-dom';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { IMedication } from '@/types';

interface MedicationsTableProps {
  medications: IMedication[];
}

export function MedicationsTable({ medications }: MedicationsTableProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Medications ({medications.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[600px]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Medication</TableHead>
                <TableHead>Variant</TableHead>
                <TableHead>Qty</TableHead>
                <TableHead>Dosage</TableHead>
                <TableHead>Brand/Generic</TableHead>
                <TableHead>Partial</TableHead>
                <TableHead>Notes</TableHead>
                <TableHead>Created</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {medications.map((medication) => (
                <TableRow key={medication.id}>
                  <TableCell className="font-medium">
                    <Link to={`/medications/${medication.id}`} className="text-blue-600 hover:underline">
                      {medication.medication}
                    </Link>
                  </TableCell>
                  <TableCell>{medication.variant}</TableCell>
                  <TableCell>{medication.quantity}</TableCell>
                  <TableCell>
                    {medication.dosage} {medication.dosage_form}
                  </TableCell>
                  <TableCell>{medication.preference}</TableCell>
                  <TableCell>
                    <Badge variant={medication.partial_stock_allowed ? 'in_stock' : 'secondary'}>
                      {medication.partial_stock_allowed ? 'Yes' : 'No'}
                    </Badge>
                  </TableCell>
                  <TableCell>{medication.note || 'No notes'}</TableCell>
                  <TableCell>
                    <Time>{medication.created_at}</Time>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
