import { IManualCallFilter } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useManualCallFilters(searchParams = {}) {
  // Filter out empty values from searchParams
  const filteredParams = Object.fromEntries(
    Object.entries(searchParams).filter(([, value]) => value !== undefined && value !== null && value !== ''),
  );

  // Encode each parameter to handle spaces and special characters
  const encodedParams = new URLSearchParams(
    Object.entries(filteredParams).map(([key, value]) => [key, encodeURIComponent(String(value))]),
  );

  const url = `admin/unique_locations?${encodedParams.toString()}`;
  const query = getUrl(url);

  return {
    ...query,
    data: query.data as IManualCallFilter,
  };
}

export default useManualCallFilters;
