import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import axiosClient from '@/utils/axiosClient';

interface CategoryModalProps {
  onSuccess?: (category: { id: string; name: string }) => void;
}

const CategoryModal = NiceModal.create(({ onSuccess }: CategoryModalProps) => {
  const modal = useModal();
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data } = await axiosClient.post('/v2/drugs/create_category', {
        drug_category: { name },
      });

      onSuccess?.(data);
      modal.hide();
    } catch (error) {
      console.error('Error creating category:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal modal={modal} title="Add New Category" description="Create a new drug category">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Label htmlFor="name">Category Name</Label>
          <Input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter category name"
            required
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button type="button" variant="outline" onClick={() => modal.hide()}>
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Category'}
          </Button>
        </div>
      </form>
    </Modal>
  );
});

export default CategoryModal;
