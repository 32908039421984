import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useAccountPatients from '@/hooks/useAccountPatients';
import { IAccountPatient } from '@/types';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import Time from '../Time';
import UserButton from '../UserButton';

const AccountPatients = () => {
  const model = useAccountPatients();
  const data = useMemo(() => model.data || [], [model.data]);
  const columns: ColumnDef<IAccountPatient>[] = useMemo(
    () => [
      {
        accessorKey: 'user.name',
        header: 'Patient',
        cell: ({ row }) => <UserButton user={row.original.user} name />,
      },
      {
        accessorKey: 'user.id',
        header: 'ID',
        cell: ({ row }) => <UserButton user={row.original.user} id />,
      },
      {
        accessorKey: 'user.email',
        header: 'Email',
        cell: ({ row }) => <UserButton user={row.original.user} />,
      },
      {
        accessorKey: 'user.dob',
        header: 'DOB',
      },
      {
        accessorKey: 'created_at',
        header: 'Invited',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
    ],
    [],
  );

  return (
    <Section>
      <DataTable data={data} columns={columns} model={model} />
    </Section>
  );
};

export default AccountPatients;
