import React, { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Plus, X } from 'lucide-react';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import useDrugCategories from '@/hooks/useDrugCategories';
import { DrugVariant, Pronunciation, DrugModalProps } from '@/types';
import axiosClient from '@/utils/axiosClient';

const DOSAGE_FORMS = [
  'Tablet',
  'Capsule',
  'Oral Solution',
  'Suspension',
  'Patch',
  'Injection',
  'Pre-filled Pens',
  'Sublingual Tablet',
  'Chewable Tablets',
  'Syringe',
];

const DrugModal = NiceModal.create(({ drug }: DrugModalProps) => {
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { data: categories = [], refetch: refetchCategories } = useDrugCategories();
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    generic: '',
    variants: [{ name: '', short: '', dosages: [''], dosageForm: '' }],
    pronunciations: [] as Pronunciation[],
  });

  useEffect(() => {
    if (drug) {
      setFormData({
        name: drug.data.name,
        category: drug.data.category,
        generic: drug.data.generic,
        variants: drug.data.variants,
        pronunciations: drug.data.pronunciations || [],
      });
    }
  }, [drug]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const orderedPronunciations = formData.pronunciations.map((p) => ({
      word: p.word,
      pronunciation: p.pronunciation,
      case_sensitive: p.case_sensitive,
      spaced: p.spaced,
    }));

    const payload = {
      name: formData.name,
      data: {
        name: formData.name,
        category: formData.category,
        generic: formData.generic,
        variants: formData.variants,
        pronunciations: orderedPronunciations,
      },
    };

    try {
      if (drug?.id) {
        await axiosClient.put(`/v2/drugs/${drug.id}`, payload);
      } else {
        await axiosClient.post('/v2/drugs', payload);
      }
      modal.hide();
      window.location.reload();
    } catch (error) {
      console.error('Error saving drug:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addVariant = () => {
    setFormData((prev) => ({
      ...prev,
      variants: [...prev.variants, { name: '', short: '', dosages: [''], dosageForm: '' }],
    }));
  };

  const removeVariant = (index: number) => {
    if (formData.variants.length > 1) {
      setFormData((prev) => ({
        ...prev,
        variants: prev.variants.filter((_, i) => i !== index),
      }));
    }
  };

  const addDosage = (variantIndex: number) => {
    setFormData((prev) => {
      const newVariants = [...prev.variants];
      newVariants[variantIndex] = {
        ...newVariants[variantIndex],
        dosages: [...newVariants[variantIndex].dosages, ''],
      };
      return { ...prev, variants: newVariants };
    });
  };

  const removeDosage = (variantIndex: number, dosageIndex: number) => {
    if (formData.variants[variantIndex].dosages.length > 1) {
      setFormData((prev) => {
        const newVariants = [...prev.variants];
        newVariants[variantIndex] = {
          ...newVariants[variantIndex],
          dosages: newVariants[variantIndex].dosages.filter((_, i) => i !== dosageIndex),
        };
        return { ...prev, variants: newVariants };
      });
    }
  };

  const updateVariant = (variantIndex: number, field: keyof DrugVariant, value: string) => {
    setFormData((prev) => {
      const newVariants = [...prev.variants];
      newVariants[variantIndex] = {
        ...newVariants[variantIndex],
        [field]: value,
      };
      return { ...prev, variants: newVariants };
    });
  };

  const updateDosage = (variantIndex: number, dosageIndex: number, value: string) => {
    setFormData((prev) => {
      const newVariants = [...prev.variants];
      newVariants[variantIndex] = {
        ...newVariants[variantIndex],
        dosages: newVariants[variantIndex].dosages.map((dosage, i) => (i === dosageIndex ? value : dosage)),
      };
      return { ...prev, variants: newVariants };
    });
  };

  const addPronunciation = () => {
    setFormData((prev) => ({
      ...prev,
      pronunciations: [
        ...prev.pronunciations,
        {
          word: '',
          pronunciation: '',
          case_sensitive: false,
          spaced: false,
        } as const,
      ],
    }));
  };

  const removePronunciation = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      pronunciations: prev.pronunciations.filter((_, i) => i !== index),
    }));
  };

  const updatePronunciation = (index: number, field: keyof Pronunciation, value: any) => {
    setFormData((prev) => ({
      ...prev,
      pronunciations: prev.pronunciations.map((p, i) =>
        i === index
          ? {
              word: field === 'word' ? value : p.word,
              pronunciation: field === 'pronunciation' ? value : p.pronunciation,
              case_sensitive: field === 'case_sensitive' ? value : p.case_sensitive,
              spaced: field === 'spaced' ? value : p.spaced,
            }
          : p,
      ),
    }));
  };

  return (
    <Modal
      modal={modal}
      title={drug ? 'Edit Drug' : 'Add New Drug'}
      description={drug ? 'Modify existing drug information' : 'Enter new drug details'}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div>
            <Label htmlFor="name">Drug Name</Label>
            <Input
              id="name"
              value={formData.name}
              onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
              placeholder="Enter drug name"
              required
            />
          </div>

          <div>
            <Label htmlFor="category">Category</Label>
            <Select
              value={formData.category}
              onValueChange={(value) => {
                if (value === 'add_new') {
                  NiceModal.show('category-modal', {
                    onSuccess: async (newCategory: { id: string; name: string }) => {
                      await refetchCategories();

                      setTimeout(() => {
                        setFormData((prev) => ({ ...prev, category: newCategory.name }));
                      }, 100);
                    },
                  });
                } else {
                  setFormData((prev) => ({ ...prev, category: value }));
                }
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                {categories.map((category) => (
                  <SelectItem key={category.id} value={category.name}>
                    {category.name}
                  </SelectItem>
                ))}
                <SelectItem value="add_new" className="text-blue-600 border-t">
                  + Add New Category
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div>
            <Label htmlFor="generic">Generic Name</Label>
            <Input
              id="generic"
              value={formData.generic}
              onChange={(e) => setFormData((prev) => ({ ...prev, generic: e.target.value }))}
              placeholder="Enter generic name"
              required
            />
          </div>

          <div className="space-y-4">
            {formData.variants.map((variant, variantIndex) => (
              <div key={`variant-${variantIndex}`} className="p-4 border rounded-lg space-y-4">
                <div className="flex justify-between items-center">
                  <h4>Variant {variantIndex + 1}</h4>
                  {formData.variants.length > 1 && (
                    <Button type="button" variant="ghost" size="sm" onClick={() => removeVariant(variantIndex)}>
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Name</Label>
                    <Input
                      value={variant.name}
                      onChange={(e) => updateVariant(variantIndex, 'name', e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <Label>Short Name</Label>
                    <Input
                      value={variant.short}
                      onChange={(e) => updateVariant(variantIndex, 'short', e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div>
                  <Label>Dosage Form</Label>
                  <Select
                    value={variant.dosageForm}
                    onValueChange={(value) => updateVariant(variantIndex, 'dosageForm', value)}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select dosage form" />
                    </SelectTrigger>
                    <SelectContent>
                      {DOSAGE_FORMS.map((form) => (
                        <SelectItem key={`form-${form}-${variantIndex}`} value={form}>
                          {form}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  {variant.dosages.map((dosage, dosageIndex) => (
                    <div key={`dosage-${variantIndex}-${dosageIndex}`} className="flex gap-2">
                      <Input
                        value={dosage}
                        onChange={(e) => updateDosage(variantIndex, dosageIndex, e.target.value)}
                        placeholder="Enter dosage (e.g., 10 mg)"
                        required
                      />
                      {variant.dosages.length > 1 && (
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          onClick={() => removeDosage(variantIndex, dosageIndex)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                  ))}
                  <Button type="button" variant="outline" size="sm" onClick={() => addDosage(variantIndex)}>
                    <Plus className="h-4 w-4 mr-2" />
                    Add Dosage
                  </Button>
                </div>
              </div>
            ))}

            <Button type="button" variant="outline" onClick={addVariant}>
              <Plus className="h-4 w-4 mr-2" />
              Add Variant
            </Button>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Label>Pronunciations</Label>
              <Button type="button" variant="outline" size="sm" onClick={addPronunciation}>
                <Plus className="h-4 w-4 mr-2" />
                Add Pronunciation
              </Button>
            </div>

            {formData.pronunciations.map((p, index) => (
              <div key={`pronunciation-${index}`} className="p-4 border rounded-lg space-y-4">
                <div className="flex justify-between items-center">
                  <h4>Pronunciation {index + 1}</h4>
                  <Button type="button" variant="ghost" size="sm" onClick={() => removePronunciation(index)}>
                    <X className="h-4 w-4" />
                  </Button>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Word</Label>
                    <Input
                      value={p.word}
                      onChange={(e) => updatePronunciation(index, 'word', e.target.value)}
                      placeholder="Enter word"
                      required
                    />
                  </div>
                  <div>
                    <Label>Pronunciation</Label>
                    <Input
                      value={p.pronunciation}
                      onChange={(e) => updatePronunciation(index, 'pronunciation', e.target.value)}
                      placeholder="e.g., ad-zen-is"
                      required
                    />
                  </div>
                </div>

                <div className="flex gap-6">
                  <label className="flex items-center space-x-2">
                    <Checkbox
                      checked={p.case_sensitive}
                      onCheckedChange={(checked) => updatePronunciation(index, 'case_sensitive', checked)}
                    />
                    <span>Case Sensitive</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <Checkbox
                      checked={p.spaced}
                      onCheckedChange={(checked) => updatePronunciation(index, 'spaced', checked)}
                    />
                    <span>Spaced</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <Button type="button" variant="outline" onClick={() => modal.hide()}>
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Saving...' : drug ? 'Update Drug' : 'Add Drug'}
          </Button>
        </div>
      </form>
    </Modal>
  );
});

export default DrugModal;
