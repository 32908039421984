import { IDrugRecord } from '@/types';
import { getUrl } from '@/utils/api';

interface DrugRecordsOptions {
  filter?: string;
  includeArchived?: boolean;
}

function useDrugRecords(filterOrOptions?: string | DrugRecordsOptions) {
  let url = 'v2/drugs';

  if (typeof filterOrOptions === 'string') {
    url += `?query=${filterOrOptions}`;
  } else if (filterOrOptions) {
    const params = [];
    if (filterOrOptions.filter) {
      params.push(`query=${filterOrOptions.filter}`);
    }
    if (filterOrOptions.includeArchived) {
      params.push('include_archived=true');
    }
    if (params.length) {
      url += `?${params.join('&')}`;
    }
  }

  const query = getUrl(url);

  return {
    ...query,
    data: query.data as IDrugRecord[],
  };
}

export default useDrugRecords;
