import { useState, useCallback } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AlertCircle, Calendar, CheckCircle2, Clock, MapPin, PhoneIcon } from 'lucide-react';
import { useMutation } from 'react-query';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import usePharmacy from '@/hooks/usePharmacy';
import axiosClient from '@/utils/axiosClient';
import Map from '../Admin/TaskDrawer/Map';
import PharmacyCallHistory from '../Admin/TaskDrawer/PharmacyCallHistory';
import Drawer from '../Modals/Drawer';
import PharmacyNotes from '../Notes/PharmacyNotes';
import Phone from '../Phone';
import { Badge } from '../ui/badge';
import { Card, CardContent } from '../ui/card';
import { DrawerHeader } from '../ui/drawer';
import { Separator } from '../ui/separator';
import PharmacyForm from './PharmacyForm';

interface Props {
  pharmacyId: string;
}

const PharmacyDrawer = NiceModal.create(({ pharmacyId }: Props) => {
  const drawer = useModal('pharmacy-drawer');
  const { data: pharmacy, isLoading } = usePharmacy(pharmacyId);

  const [tab, setTab] = useState<string | undefined>('pharmacy');

  const mutation = useMutation(
    async ({ taskId, status }: { taskId: string; status: string }) => {
      console.log(`Updating task ${taskId} to status ${status}`);
      const response = await axiosClient.put(`/admin/tasks/${taskId}`, { stock_status: status, status: 'completed' });
      return response.data;
    },
    {
      onSuccess: () => {},
      onError: (error) => {
        console.error('Error updating task status:', error);
      },
    },
  );

  const handleUpdateStatus = useCallback(
    async (taskId: string, status: string): Promise<void> => {
      await mutation.mutateAsync({ taskId, status });
    },
    [mutation],
  );

  if (isLoading || !pharmacy?.id) {
    return null;
  }

  return (
    <Drawer modal={drawer}>
      <div className="mr-1">
        <DrawerHeader className="text-left">
          <div className="flex items-start justify-between">
            <div>
              <h2 className="text-2xl font-bold">{pharmacy.name}</h2>
              <p className="text-sm text-muted-foreground">ID: {pharmacy.id}</p>
            </div>
            <div className="flex items-center space-x-2">
              {pharmacy.invalid_pharmacy ? (
                <Badge variant="outline" className="text-green-700 border-green-200 bg-green-50">
                  <CheckCircle2 className="w-3 h-3 mr-1" />
                  Valid
                </Badge>
              ) : (
                <Badge variant="outline" className="text-red-700 border-red-200 bg-red-50">
                  <AlertCircle className="w-3 h-3 mr-1" />
                  Invalid
                </Badge>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 mt-4 gap-y-2">
            <div className="flex items-center text-sm">
              <MapPin className="w-4 h-4 mr-2 text-muted-foreground" />
              <span>
                {pharmacy.address1}, {pharmacy.address2}, {pharmacy.city}, {pharmacy.state} {pharmacy.zip}
              </span>
            </div>
            <div className="flex items-center text-sm">
              <PhoneIcon className="w-4 h-4 mr-2 text-muted-foreground" />
              <Phone>{pharmacy.phone}</Phone>
            </div>
            <div className="flex items-center text-sm">
              <Clock className="w-4 h-4 mr-2 text-muted-foreground" />
              <span>
                {pharmacy.open_time} - {pharmacy.close_time}
              </span>
            </div>
            <div className="flex items-center text-sm">
              <Calendar className="w-4 h-4 mr-2 text-muted-foreground" />
              <span>Mon - Fri</span>
            </div>
          </div>
        </DrawerHeader>
        <Separator className="my-4" />

        <Tabs defaultValue={tab} className="w-full" onValueChange={setTab}>
          <TabsList>
            <TabsTrigger value="pharmacy">Pharmacy</TabsTrigger>
            <TabsTrigger value="call_history">Call History</TabsTrigger>
            <TabsTrigger value="location">Google Map</TabsTrigger>
            <TabsTrigger value="notes">Notes</TabsTrigger>
          </TabsList>
          <ScrollArea className="h-[calc(100vh-150px)]">
            <TabsContent value="pharmacy">
              <Card>
                <CardContent className="mt-2">
                  <PharmacyForm pharmacy={pharmacy} />
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="call_history">
              <PharmacyCallHistory pharmacy={pharmacy} onUpdateStatus={handleUpdateStatus} />
            </TabsContent>
            <TabsContent value="location">
              <Map pharmacy={pharmacy} />
            </TabsContent>
            <TabsContent value="notes">
              <PharmacyNotes pharmacyId={pharmacy.id} />
            </TabsContent>
          </ScrollArea>
        </Tabs>
      </div>
    </Drawer>
  );
});

export default PharmacyDrawer;
