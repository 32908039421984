import React, { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { PlusIcon, Search, Edit, Trash2, Archive, ArchiveRestore } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useDrugRecords from '@/hooks/useDrugRecords';
import type { Drug } from '@/types';
import axiosClient from '@/utils/axiosClient';

const Drugs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<'active' | 'archived'>('active');
  const { data: drugs, isLoading, error } = useDrugRecords({ includeArchived: true });
  const drugModal = useModal('drug-modal');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogOnConfirm, setDialogOnConfirm] = useState<() => void>(() => {});

  const filteredDrugs =
    drugs
      ?.filter((drug) => {
        const matchesSearch =
          drug.data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          drug.data.generic.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesArchiveStatus = activeTab === 'active' ? !drug.archived : drug.archived;
        return matchesSearch && matchesArchiveStatus;
      })
      .sort((a, b) => a.data.name.localeCompare(b.data.name)) || [];

  const handleEdit = (drug: Drug) => {
    drugModal.show({ drug });
  };

  const handleDelete = (drugId: string) => {
    setDialogTitle('Delete Drug');
    setDialogDescription(
      'Are you sure you want to delete this drug? Be very careful with this as it cannot be undone.',
    );
    setDialogOnConfirm(() => async () => {
      try {
        await axiosClient.delete(`/v2/drugs/${drugId}`);
        window.location.reload();
      } catch (error) {
        console.error('Error deleting drug:', error);
      } finally {
        setOpenDialog(false);
      }
    });
    setOpenDialog(true);
  };

  const handleArchiveToggle = (drugId: string, currentArchived: boolean | undefined) => {
    setDialogTitle(currentArchived ? 'Unarchive Drug' : 'Archive Drug');
    setDialogDescription(
      currentArchived ? 'Are you sure you want to unarchive this drug?' : 'Are you sure you want to archive this drug?',
    );
    setDialogOnConfirm(() => async () => {
      try {
        await axiosClient.put(`/v2/drugs/${drugId}`, {
          archived: !currentArchived,
        });
        window.location.reload();
      } catch (error) {
        console.error('Error toggling archive status:', error);
      } finally {
        setOpenDialog(false);
      }
    });
    setOpenDialog(true);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-64">
        <div className="text-gray-500">Loading drugs...</div>
      </div>
    );
  }

  if (error) {
    return <div className="p-4 text-red-500">Failed to load drugs. Please try again later.</div>;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col items-start justify-between gap-4 mb-8 md:flex-row md:items-center">
        <div>
          <h2 className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">Drug Management</h2>
          <p className="max-w-sm mt-2 text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            Manage and organize the drug database.
          </p>
        </div>
        <Button
          className="items-center w-full gap-2 transition-all duration-300 rounded-full shadow-sm hover:shadow-md sm:w-auto"
          onClick={() => drugModal.show()}
        >
          <PlusIcon className="w-6 h-6" />
          Add Drug
        </Button>
      </div>

      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'active' | 'archived')} className="mb-6">
        <TabsList>
          <TabsTrigger value="active">Active Drugs</TabsTrigger>
          <TabsTrigger value="archived">Archived Drugs</TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="flex items-center w-full mb-8">
        <div className="relative flex-1 md:w-64">
          <Search className="absolute w-4 h-4 transform -translate-y-1/2 left-2 top-1/2 text-muted-foreground" />
          <Input
            className="pl-8"
            placeholder="Search drugs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoComplete="off"
            spellCheck={false}
          />
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {filteredDrugs.map((drug) => (
          <Card key={drug.id} className="flex flex-col h-full">
            <CardHeader>
              <CardTitle className="text-lg font-medium">{drug.data.name}</CardTitle>
            </CardHeader>

            <CardContent className="flex-grow">
              <p className="text-sm text-muted-foreground">{drug.data.generic}</p>
              <p className="mt-2 text-sm font-medium">Category: {drug.data.category}</p>

              <div className="mt-2">
                <p className="text-sm font-medium">Variants:</p>
                <div className="mt-1 space-y-1">
                  {drug.data.variants.map((variant, variantIndex) => (
                    <div key={`${drug.id}-variant-${variantIndex}`} className="text-sm text-muted-foreground">
                      {variant.name} ({variant.short}) - {variant.dosageForm}
                    </div>
                  ))}
                </div>
              </div>

              {drug.data.pronunciations && drug.data.pronunciations.length > 0 && (
                <div className="mt-2">
                  <p className="text-sm font-medium">Pronunciations:</p>
                  <div className="mt-1 space-y-1">
                    {drug.data.pronunciations.map((p, index) => (
                      <div key={`${drug.id}-pronunciation-${index}`} className="text-sm text-muted-foreground">
                        {p.word}: {p.pronunciation}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </CardContent>

            <CardFooter className="flex justify-between gap-2">
              <Button variant="outline" onClick={() => handleEdit(drug)}>
                <Edit className="w-4 h-4 mr-2" />
                Edit
              </Button>
              <div className="flex gap-2">
                <Button variant="destructive" onClick={() => handleDelete(drug.id)}>
                  <Trash2 className="w-4 h-4 mr-2" />
                  Delete
                </Button>
                <Button variant="purple" onClick={() => handleArchiveToggle(drug.id, drug.archived)}>
                  {drug.archived ? (
                    <>
                      <ArchiveRestore className="w-4 h-4 mr-2" />
                      Unarchive
                    </>
                  ) : (
                    <>
                      <Archive className="w-4 h-4 mr-2" />
                      Archive
                    </>
                  )}
                </Button>
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>

      {!filteredDrugs.length && (
        <div className="p-8 mt-8 text-center text-gray-500 rounded-lg shadow-inner bg-gray-50">
          <p className="mb-3 text-xl font-medium">
            {searchTerm.length > 0 ? 'No drugs found' : `No ${activeTab} drugs`}
          </p>
          <p className="mb-4 text-sm">
            {searchTerm.length > 0
              ? 'Try adjusting your search terms'
              : activeTab === 'active'
                ? 'Click the "Add Drug" button to get started'
                : 'No archived drugs found'}
          </p>
          {!searchTerm && activeTab === 'active' && (
            <Button onClick={() => drugModal.show()}>
              <PlusIcon className="w-4 h-4 mr-2" />
              Add Drug
            </Button>
          )}
        </div>
      )}

      <AlertDialog open={openDialog} onOpenChange={setOpenDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{dialogTitle}</AlertDialogTitle>
            <AlertDialogDescription>{dialogDescription}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpenDialog(false)}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={dialogOnConfirm}>Confirm</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Drugs;
