import { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { XIcon } from 'lucide-react';
import { Input } from '@/components/ui/input.tsx';
import useDrugRecords from '@/hooks/useDrugRecords';
import { ScrollArea } from '../ui/scroll-area';
import { Table, TableBody, TableCell, TableRow } from '../ui/table';

interface DrugSelectorProps {
  handleDrugSelect: (drug: string) => void;
}
const DrugSelector = ({ handleDrugSelect }: DrugSelectorProps) => {
  const [search, setSearch] = useState('');
  const { data: drugRecords = [] } = useDrugRecords();

  const highlightMatch = (text: string, search: string) => {
    if (!search) return text;
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} className="bg-purple-300">
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  const sortedDrugs = drugRecords.map((record) => record.data).sort((a, b) => a.name.localeCompare(b.name));

  const results = sortedDrugs.filter((drug) => {
    return (
      drug.name.toLowerCase().includes(search.toLowerCase()) ||
      drug.generic.toLowerCase().includes(search.toLowerCase()) ||
      drug.variants.some((variant) => variant.name.toLowerCase().includes(search.toLowerCase()))
    );
  });

  return (
    <>
      <div className="flex items-center w-full gap-2 px-2 mb-4 border border-gray-300 rounded-lg">
        <MagnifyingGlassIcon className={'w-6 h-6 text-gray-500'} />
        <Input
          type="text"
          placeholder="Search for a medication"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full -ml-2 text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none focus-visible:ring-0 focus-visible:ring-white focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
        />
        {search && (
          <button onClick={() => setSearch('')} title="Clear search">
            <XIcon className="w-4 h-4" />
          </button>
        )}
      </div>

      <ScrollArea className="border border-gray-300 rounded-lg h-72">
        <Table>
          <TableBody>
            {results.map((drug) => (
              <TableRow onClick={() => handleDrugSelect(drug.name)} className="cursor-pointer hover:bg-gray-100">
                <TableCell className="p-3 align-top">
                  <div className="text-lg font-semibold text-gray-700 whitespace-nowrap">
                    {highlightMatch(drug.name, search)}
                  </div>
                  <div className="text-gray-700 whitespace-nowrap">({highlightMatch(drug.generic, search)})</div>
                </TableCell>
                <TableCell className="align-top">
                  {drug.variants.map((variant) => (
                    <div key={drug.name + variant.name} className="text-xs text-gray-500 text-pretty">
                      {highlightMatch(variant.name, search)} ({variant.short})
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}

            {!results.length && (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className="flex flex-col items-center justify-center h-64 gap-2 p-4 text-gray-600"
                >
                  <div>There are no medications matching your search.</div>
                  <div>Try searching for a generic name or message us.</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>

      <div className="flex flex-col gap-2 mt-4 text-xs text-center text-purple-500">
        <div>Can't find the medication you're looking for?</div>
        <div>
          Chat or{' '}
          <a href="mailto:mail@findneedle.co" className="text-purple-500">
            email
          </a>{' '}
          us. We can add any medication.
        </div>
      </div>
    </>
  );
};

export default DrugSelector;
