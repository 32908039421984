import { useState } from 'react';
import useEmployees from '@/hooks/useEmployees';
import useManualCalls from '@/hooks/useManualCalls';
import useMe from '@/hooks/useMe';
import Filters from './Filters';
import TasksTable from './TasksTable';

type IFilters = {
  selectedCity: string;
  selectedState: string;
  selectedRequest: string;
  selectedMedication: string;
  selectedAssignedTo: string;
  groupByOption: string;
};

const Calls = () => {
  const currentUser = useMe();
  const employees = useEmployees();

  const [filters, setFilters] = useState<IFilters>({
    selectedCity: '',
    selectedState: '',
    selectedRequest: '',
    selectedMedication: '',
    selectedAssignedTo: '',
    groupByOption: 'none',
  });

  const { data: tasks, isLoading } = useManualCalls({
    medication: filters.selectedMedication,
    city: filters.selectedCity,
    state: filters.selectedState,
    requestId: filters.selectedRequest,
    assignedTo: filters.selectedAssignedTo,
  });

  if (isLoading || !employees || !currentUser) {
    return <div>Loading...</div>;
  }

  const myTasks = Array.isArray(tasks) ? tasks.filter((task) => task.assigned_user_id === currentUser?.data?.id) : [];
  const queueTasks = Array.isArray(tasks)
    ? tasks.filter((task) => task.assigned_user_id !== currentUser?.data?.id)
    : [];

  return (
    <div className="w-full ">
      <Filters filters={filters} setFilters={setFilters} employees={employees?.data} />

      <div className="flex flex-col gap-4 md:flex-row">
        <TasksTable
          title="My Calls"
          employees={employees?.data}
          tasks={myTasks}
          isLoading={isLoading}
          groupByOption={filters.groupByOption}
        />
        <TasksTable
          title="Queue"
          employees={employees?.data}
          tasks={queueTasks}
          isLoading={isLoading}
          groupByOption={filters.groupByOption}
        />
      </div>
    </div>
  );
};

export default Calls;
