import { IPharmacyGroup } from '@/types';
import { getUrl } from '@/utils/api';

function usePharmacyGroups() {
  const query = getUrl('v1/pharmacy_groups');
  return {
    ...query,
    data: query.data as IPharmacyGroup[],
  };
}

export default usePharmacyGroups;
