import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { IPharmacy } from '@/types';
import { formatPhoneNumber } from '@/utils/helpers';

interface PharmaciesTableProps {
  pharmacies: IPharmacy[];
}

export function PharmaciesTable({ pharmacies }: PharmaciesTableProps) {
  const uniquePharmacies = pharmacies.filter(
    (pharmacy, index, self) => index === self.findIndex((t) => t.id === pharmacy.id),
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Latest Pharmacies ({uniquePharmacies.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>City</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {uniquePharmacies.map((pharmacy) => (
              <TableRow key={pharmacy.id}>
                <TableCell className="font-medium">{pharmacy.name}</TableCell>
                <TableCell>{formatPhoneNumber(pharmacy.phone)}</TableCell>
                <TableCell>{pharmacy.address1}</TableCell>
                <TableCell>{pharmacy.city}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
