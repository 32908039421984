import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { IUser } from '@/types';
import { USDollar } from '@/utils/helpers';

interface CustomerInfoProps {
  user: IUser;
}

export function CustomerInfo({ user }: CustomerInfoProps) {
  const latestRequest =
    user?.requests?.length > 0
      ? [...user.requests].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
      : null;

  const getContactPreference = () => {
    if (user?.prefers_sms) {
      return 'SMS';
    } else if (user?.prefers_email) {
      return 'Email';
    } else {
      return 'Phone';
    }
  };

  return (
    <>
      <CardHeader>
        <CardTitle>Customer Info</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[200px] pr-4">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-sm text-muted-foreground">Plan:</span>
              <Badge variant="default">{user?.plan || 'NO PLAN'}</Badge>
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-muted-foreground">Available Credits:</span>
              <span className="text-sm font-medium">{USDollar.format(user?.total_credits || 0)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-muted-foreground">Last Request:</span>
              <span className="text-sm font-medium">
                {latestRequest ? <Time>{latestRequest.created_at}</Time> : 'No requests'}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-muted-foreground">Contact Preference:</span>
              <span className="text-sm font-medium">{getContactPreference()}</span>
            </div>
          </div>
        </ScrollArea>
      </CardContent>
    </>
  );
}
