import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Badge, Building } from 'lucide-react';
import Phone from '@/components/Phone';
import Section from '@/components/Section';
import Time from '@/components/Time';
import { IPharmacy } from '@/types';
import PaginatedDataTable from '../DataTable/PaginatedDataTable';
import PharmacyButton from '../PharmacyButton';

const Pharmacies = () => {
  const columns: ColumnDef<IPharmacy>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        defaultSort: 'asc',
        cell: ({ row }) => (
          <>
            <PharmacyButton pharmacy={row.original} />
          </>
        ),
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        cell: ({ row }) => <Phone>{row.original.phone}</Phone>,
      },
      {
        accessorKey: 'invalid_pharmacy',
        header: 'Invalid',
        cell: ({ row }) => {
          row.original.invalid_pharmacy == true ? <Badge>Invalid</Badge> : '';
        },
      },
      {
        accessorKey: 'address1',
        header: 'Address',
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'zip',
        header: 'Zip',
      },
      {
        accessorKey: 'created_at',
        header: 'Created',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex items-center justify-between gap-2 mb-4">
        <div className="flex items-center gap-2 ">
          <Building />
          <div className="text-lg font-bold">Pharmacies</div>
        </div>
      </div>

      <Section>
        <PaginatedDataTable url={'/admin/pharmacies'} columns={columns} />
      </Section>
    </>
  );
};

export default Pharmacies;
