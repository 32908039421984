import { compareItems } from '@tanstack/match-sorter-utils';
import { ColumnDef, SortingFn, sortingFns } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/DataTable/DataTableColumnHeader';
import PharmacyButton from '@/components/PharmacyButton';
import { StatusIndicator } from '@/components/Requests/StatusIndicator';
import { Badge } from '@/components/ui/badge';
import { ITask } from '@/types';
import { formatPhoneNumber } from '@/utils/helpers';

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank, rowB.columnFiltersMeta[columnId]?.itemRank);
  }

  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};
export const PharmaciesTaskTableColumns: ColumnDef<ITask>[] = [
  {
    id: 'Task Status',
    accessorKey: 'status',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => (
      <Badge color="blue" className="text-xs">
        {row.original.status}
      </Badge>
    ),
  },
  {
    id: 'Stock',
    accessorKey: 'stock_status',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => (
      <>{row.original.stock_status ? <StatusIndicator status={row.original.stock_status as string} /> : 'N/A'}</>
    ),
  },
  {
    id: 'Scheduled',
    accessorKey: 'scheduled_at',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Scheduled" />,
    cell: ({ row }) => <>{row.original.scheduled_at ? new Date(row.original.scheduled_at).toLocaleString() : 'N/A'}</>,
  },
  {
    id: 'Total Calls',
    accessorFn: (row) => row.calls?.length,
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Total Calls" />,
    cell: ({ row }) => <div className="text-center">{row.original.calls?.length}</div>,
  },
  {
    id: 'Task ID',
    accessorKey: 'id',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Task ID" />,
    cell: ({ row }) => <>{row.original.id}</>,
  },
  {
    id: 'Pharmacy ID',
    accessorKey: 'pharmacy.id',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Pharmacy ID" />,
    cell: ({ row }) => (
      <>
        <PharmacyButton pharmacy={row.original.pharmacy} />
      </>
    ),
  },
  {
    id: 'Medication ID',
    accessorKey: 'medication.id',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Medication ID" />,
    cell: ({ row }) => <>{row.original.medication.id}</>,
  },
  {
    id: 'Pharmacy',
    accessorKey: 'pharmacy.name',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Pharmacy" />,
    cell: ({ row }) => (
      <>
        <PharmacyButton pharmacy={row.original.pharmacy} />
      </>
    ),
  },
  {
    id: 'Phone',
    accessorKey: 'pharmacy.phone',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Phone" />,
    cell: ({ row }) => (
      <>
        <div className="whitespace-pre">{formatPhoneNumber(row.original.pharmacy.phone)}</div>
      </>
    ),
  },
  {
    id: 'Medication',
    accessorKey: 'medication.medication',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Medication" />,
    cell: ({ row }) => <>{row.original.medication.medication}</>,
  },
  {
    id: 'Variant',
    accessorKey: 'medication.variant',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Variant" />,
    cell: ({ row }) => <>{row.original.medication.variant}</>,
  },
  {
    id: 'Dosage',
    accessorKey: 'medication.dosage',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Dosage" />,
    cell: ({ row }) => <>{row.original.medication.dosage}</>,
  },
  {
    id: 'Quantity',
    accessorKey: 'medication.quantity',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Quantity" />,
    cell: ({ row }) => <>{row.original.medication.quantity}</>,
  },
  {
    id: 'Address',
    accessorKey: 'pharmacy.address1',
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
    header: ({ column }) => <DataTableColumnHeader column={column} title="Address" />,
    cell: ({ row }) => <>{row.original.pharmacy.address1}</>,
  },
  {
    id: 'City',
    accessorKey: 'pharmacy.city',
    header: ({ column }) => <DataTableColumnHeader column={column} title="City" />,
    cell: ({ row }) => <>{row.original.pharmacy.city}</>,
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
  },
  {
    id: 'State',
    accessorKey: 'pharmacy.state',
    header: ({ column }) => <DataTableColumnHeader column={column} title="State" />,
    cell: ({ row }) => <>{row.original.pharmacy.state}</>,
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
  },
  {
    id: 'Zip',
    accessorKey: 'pharmacy.zip',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Zip" />,
    cell: ({ row }) => <>{row.original.pharmacy.zip}</>,
    filterFn: 'fuzzy',
    sortingFn: fuzzySort,
  },
];
