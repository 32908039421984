import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Form, FormField, FormItem, FormControl, FormMessage } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import useNotes from '@/hooks/useNotes';
import { PharmacyNotesProps } from '@/types';
import axiosClient from '@/utils/axiosClient';

const schema = z.object({
  content: z.string().nonempty({ message: 'Note content is required' }),
});

type FormData = z.infer<typeof schema>;

const PharmacyNotes: React.FC<PharmacyNotesProps> = ({ pharmacyId }) => {
  const { data: notes } = useNotes({
    notableType: 'Pharmacy',
    notableId: pharmacyId,
  });

  const queryClient = useQueryClient();
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { content: '' },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await axiosClient.post('/admin/notes', {
        note: {
          content: data.content,
          notable_type: 'Pharmacy',
          notable_id: pharmacyId,
        },
      });

      queryClient.invalidateQueries(['notes', 'Pharmacy', pharmacyId]);
      form.reset();
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  return (
    <Card>
      <CardContent className="pt-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea {...field} placeholder="Add a note..." className="resize-none" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end">
              <Button type="submit">Add Note</Button>
            </div>
          </form>
        </Form>

        <div className="mt-6 space-y-4">
          {notes?.map((note) => (
            <div key={note.id} className="p-4 rounded-lg border border-border">
              <p className="text-sm text-foreground">{note.content}</p>
              <div className="mt-2 flex items-center text-xs text-muted-foreground">
                <span>{`${note.user.first_name} ${note.user.last_name}`}</span>
                <span className="mx-2">•</span>
                <time dateTime={note.created_at}>{new Date(note.created_at).toLocaleString()}</time>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default PharmacyNotes;
