import { useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useParams } from 'react-router';
import AppSidebar from '@/components/AppSidebar';
import useMe from '@/hooks/useMe';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from './ui/breadcrumb';
import { Separator } from './ui/separator';
import { Sidebar, SidebarInset, SidebarProvider, SidebarTrigger } from './ui/sidebar';

export default function Layout({ children }: { children: React.ReactNode }) {
  const currentUser = useMe();
  const { requestId, taskId } = useParams();
  const taskModal = useModal('task-modal');

  useEffect(() => {
    if (taskId) {
      taskModal.show({ requestId, taskId });
    }
  }, [requestId, taskId]);

  return (
    <SidebarProvider>
      <Sidebar collapsible="icon">
        {currentUser.isLoading || currentUser.data === null ? (
          <div>Loading...</div>
        ) : (
          <AppSidebar currentUser={currentUser.data} />
        )}
      </Sidebar>
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="h-4 mr-2" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="hidden md:block">
                  <BreadcrumbLink href="#">Needle</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <div className="flex flex-col flex-1 gap-4 ">
          <div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min flex-col  gap-4 p-4 pt-0">
            {children}
          </div>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
