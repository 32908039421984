import { Plus, Minus } from 'lucide-react';
import { Link } from 'react-router-dom';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ICreditTransaction } from '@/types';
import { USDollar } from '@/utils/helpers';

interface TransactionsTableProps {
  transactions: ICreditTransaction[];
  totalCredits: number;
  onCreditDebit: () => void;
  compact?: boolean;
}

export function TransactionsTable({
  transactions,
  totalCredits,
  onCreditDebit,
  compact = false,
}: TransactionsTableProps) {
  const sortedTransactions = [...transactions].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  const displayTransactions = compact ? sortedTransactions.slice(0, 5) : sortedTransactions;

  return (
    <>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Transactions {!compact && `(${transactions.length})`}</CardTitle>
        <div className="flex items-center gap-4">
          <Button onClick={onCreditDebit} variant="outline" size="sm" className="gap-2">
            <Plus className="h-4 w-4" />
            <Minus className="h-4 w-4" />
          </Button>
          <Badge variant="secondary">Balance: {USDollar.format(totalCredits)}</Badge>
        </div>
      </CardHeader>
      <CardContent>
        <ScrollArea className={compact ? 'h-[200px]' : 'h-[500px]'}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Amount</TableHead>
                {!compact && <TableHead>Type</TableHead>}
                <TableHead>Note</TableHead>
                {!compact && <TableHead>Details</TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {displayTransactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>
                    <Time>{transaction.created_at}</Time>
                  </TableCell>
                  <TableCell className={transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}>
                    {USDollar.format(transaction.amount)}
                  </TableCell>
                  {!compact && (
                    <TableCell>
                      <Badge variant={transaction.transaction_type === 'credit' ? 'in_stock' : 'secondary'}>
                        {transaction.transaction_type}
                      </Badge>
                    </TableCell>
                  )}
                  <TableCell className="max-w-[200px] truncate">
                    {transaction.note.includes('Request:') ? (
                      <Link
                        to={`/admin/requests/${transaction.note.match(/Request: (\w+-\w+-\w+-\w+-\w+)/)?.[1]}`}
                        className="text-blue-600 hover:underline"
                      >
                        {transaction.note}
                      </Link>
                    ) : (
                      transaction.note
                    )}
                  </TableCell>
                  {!compact && <TableCell>{transaction.description}</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </>
  );
}
