import { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { useQuery } from 'react-query';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import axiosClient from '@/utils/axiosClient';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

interface DataTableProps<T> {
  columns: ColumnDef<T>[];
  url: string;
  placeholder?: string;
}

const PaginatedDataTable = <T,>({ columns, url, placeholder = 'Search...' }: DataTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string>('');

  const db = useQuery(
    [url, currentPage, searchQuery, sortColumn, sortOrder, pageSize],
    () =>
      axiosClient.get(url, {
        params: {
          page: currentPage + 1,
          per_page: pageSize,
          query: searchQuery,
          sort: sortOrder,
          sortColumn,
        },
      }),
    {
      keepPreviousData: true,
      onError: (error) => {
        console.error('Error fetching data:', error);
      },
    },
  );

  const data = db?.data?.data?.results;
  const totalPages = db?.data?.data?.totalPages || 1;
  const totalCount = db?.data?.data?.totalCount || 0;

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(0);
  };

  const handleNextPage = () => {
    if (currentPage + 1 < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleSort = (changes: any) => {
    setSorting(changes);
    const sort = changes();

    if (sort?.length === 0) {
      setSortColumn(sort[0].id);
      setSortOrder(sort[0].desc ? 'desc' : 'asc');
      setCurrentPage(0);
    }
  };

  const table = useReactTable({
    data,
    columns,
    pageCount: totalPages,
    state: {
      pagination: { pageIndex: currentPage, pageSize },
      sorting,
    },
    onPaginationChange: ({ pageIndex, pageSize }: any) => {
      setCurrentPage(pageIndex);
      setPageSize(pageSize);
    },
    onSortingChange: handleSort,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  } as any);

  const paginationSummary =
    totalCount > 0
      ? `Showing ${currentPage * pageSize + 1} - ${Math.min((currentPage + 1) * pageSize, totalCount)} of ${totalCount} results`
      : 'No results found';

  return (
    <div>
      <Input
        value={searchQuery || ''}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setCurrentPage(0);
        }}
        placeholder={placeholder}
        className="mb-4"
      />

      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                      className="flex items-center gap-1"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <ArrowUp size={14} />,
                        desc: <ArrowDown size={14} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {db?.isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>Loading...</TableCell>
            </TableRow>
          ) : db?.isError ? (
            <TableRow>
              <TableCell colSpan={columns.length}>An error occurred</TableCell>
            </TableRow>
          ) : (
            <>
              {table.getRowModel()?.rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="text-center text-gray-500">
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>

      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-600">Page Size:</span>
          <Select value={String(pageSize)} onValueChange={(value) => handlePageSizeChange(Number(value))}>
            <SelectTrigger className="w-32">
              <SelectValue placeholder={`${pageSize}`} />
            </SelectTrigger>
            <SelectContent>
              {[5, 10, 25, 50, 100].map((size) => (
                <SelectItem key={size} value={String(size)}>
                  {size} per page
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <span className="text-sm text-gray-600">{paginationSummary}</span>
        <div className="flex items-center gap-2">
          <Button variant="ghost" disabled={currentPage === 0} onClick={handlePreviousPage}>
            Previous
          </Button>
          <span>
            Page {currentPage + 1} of {totalPages}
          </span>
          <Button variant="ghost" disabled={currentPage + 1 >= totalPages} onClick={handleNextPage}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaginatedDataTable;
