import { Link } from 'react-router-dom';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { IRequest } from '@/types';

interface RequestsCompactProps {
  requests?: IRequest[];
}

export function RequestsCompact({ requests = [] }: RequestsCompactProps) {
  const sortedRequests = [...requests].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <>
      <CardHeader>
        <CardTitle>Recent Requests ({requests.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[200px]">
          <div className="space-y-4 pr-4">
            {sortedRequests.map((request) => (
              <div key={request.id} className="p-3 rounded-lg border">
                <div className="flex items-center justify-between gap-2">
                  <Link
                    to={`/admin/requests/${request.id}`}
                    className="text-sm font-medium text-blue-600 hover:underline"
                  >
                    {request.id}
                  </Link>
                  <Badge variant={request.status === 'completed' ? 'in_stock' : 'secondary'}>{request.status}</Badge>
                </div>
                {request.location?.address && (
                  <div className="mt-2 text-sm break-words">
                    <span className="text-muted-foreground">Address: </span>
                    {request.location.address}
                  </div>
                )}
                <div className="mt-2">
                  <Time className="text-xs text-muted-foreground">{request.created_at}</Time>
                </div>
              </div>
            ))}
            {requests.length === 0 && <div className="text-center text-muted-foreground py-4">No requests</div>}
          </div>
        </ScrollArea>
      </CardContent>
    </>
  );
}
