import React from 'react';
import { ColumnDef, flexRender, Table as TableDef } from '@tanstack/react-table';
import { Check } from 'lucide-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { cn } from '@/utils/utils';

interface DataProps<TData, TValue> {
  table: TableDef<TData>;
  columns: ColumnDef<TData, TValue>[];
  onRowClick?: (row: TData) => void;
  emptyMessage?: string | React.ReactNode;
  selectedRowIndex?: number;
  selectedTaskId?: string;
}

const DataTableRenderer = <TData extends { id: string }, TValue>({
  table,
  columns,
  onRowClick,
  emptyMessage = 'No results.',
  selectedRowIndex = -1,
  selectedTaskId,
}: DataProps<TData, TValue>) => {
  const handleRowClick = (row: TData) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <Table className="relative w-full text-xs bg-white rounded-md">
      <TableHeader className="sticky top-0 bg-white shadow z-[2]">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </TableHead>
            ))}
            <TableHead>Selected</TableHead>
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row, index) => (
            <TableRow
              key={row.id}
              className={cn(
                'w-full cursor-pointer hover:bg-brand-purple/10',
                (selectedRowIndex === index || selectedTaskId === row.original.id) && 'bg-purple-100',
              )}
              onClick={() => handleRowClick(row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
              <TableCell>
                {(selectedRowIndex === index || selectedTaskId === row.original.id) && (
                  <Check className="w-4 h-4 text-purple-600" />
                )}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length + 1} className="h-24 text-center">
              {emptyMessage}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default DataTableRenderer;
