import { useRef, useEffect, useState } from 'react';
import { Send } from 'lucide-react';
import { useQueryClient } from 'react-query';
import Logo from '@/assets/logo-pin.svg';
import Time from '@/components/Time';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import useTextMessages from '@/hooks/useTextMessages';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';

interface ChatFeedProps {
  userId: string;
}

export function ChatFeed({ userId }: ChatFeedProps) {
  const queryClient = useQueryClient();
  const text_messages = useTextMessages(userId);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [text_messages.data]);

  const handleNewMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      await axiosClient.post('v2/text_messages', {
        body: newMessage,
        user_id: userId,
      });

      queryClient.invalidateQueries(['v2/text_messages', userId]);
      setNewMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleNewMessage();
    }
  };

  return (
    <Card className="h-[calc(100vh-16rem)] flex flex-col">
      {/* Card Header */}
      <CardHeader>
        <CardTitle>Communication History</CardTitle>
      </CardHeader>

      {/* Card Content */}
      <CardContent className="flex flex-col flex-1 overflow-hidden">
        {/* Scrollable Message Area */}
        <ScrollArea className="flex-1 p-4">
          {text_messages.isLoading ? (
            <div className="flex items-center justify-center h-full">Loading messages...</div>
          ) : text_messages.data?.length === 0 ? (
            <div className="flex items-center justify-center h-full text-muted-foreground">No messages yet</div>
          ) : (
            <div className="space-y-4">
              {text_messages.data?.map((message) => {
                const isInbound = message.direction === 'inbound';
                const author = isInbound
                  ? message.user.name || message.user.email
                  : message?.author?.id
                    ? message?.author.name
                    : 'System';

                return (
                  <div key={message.id} className={cn('flex', isInbound ? 'justify-end' : 'justify-start')}>
                    <div
                      className={cn('flex items-start max-w-[70%] gap-2', isInbound ? 'flex-row-reverse' : 'flex-row')}
                    >
                      {/* Avatar or System Logo */}
                      {isInbound ? (
                        <Avatar className="h-8 w-8">
                          <AvatarImage src={message?.user?.picture} />
                          <AvatarFallback>{message?.user?.initials}</AvatarFallback>
                        </Avatar>
                      ) : message?.author?.id ? (
                        <Avatar className="h-8 w-8">
                          <AvatarImage src={message?.author?.picture} />
                          <AvatarFallback>{message?.author?.initials}</AvatarFallback>
                        </Avatar>
                      ) : (
                        <img src={Logo} className="w-8 h-8" alt="System" />
                      )}

                      {/* Message Content */}
                      <div>
                        <div
                          className={cn(
                            'rounded-lg p-3 max-w-[calc(100%-2rem)]',
                            isInbound
                              ? 'bg-primary text-primary-foreground rounded-br-none'
                              : 'bg-muted rounded-bl-none',
                          )}
                        >
                          <p className="text-sm whitespace-pre-wrap break-words">{message.body}</p>
                        </div>
                        <div
                          className={cn(
                            'flex gap-2 mt-1 text-xs text-muted-foreground',
                            isInbound ? 'justify-end' : 'justify-start',
                          )}
                        >
                          <span>{author}</span>
                          <Time>{message.created_at}</Time>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
          )}
        </ScrollArea>

        {/* Input Area */}
        <div className="flex items-center gap-2 pt-4 border-t mt-4">
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type a message..."
            className="flex-grow"
          />
          <Button size="icon" onClick={handleNewMessage} disabled={!newMessage.trim()}>
            <Send className="h-4 w-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
