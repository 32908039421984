import { useState } from 'react';
import { UserPlus, XCircle, Loader } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from '@/components/ui/select';
import useMe from '@/hooks/useMe';
import { IEmployee, IManualCall } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';

interface TaskAssigmentProps {
  task: IManualCall;
  employees: IEmployee[];
}

const TaskAssigment = ({ task, employees }: TaskAssigmentProps) => {
  const currentUser = useMe();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  if (!task?.id) {
    return null;
  }

  const isAssignedToMe = task.assigned_user_id === currentUser?.data?.id;

  const sortedEmployees =
    employees?.sort((a, b) => {
      const nameA = a.user.name || '';
      const nameB = b.user.name || '';
      return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
    }) || [];

  const save = async (assigned_user_id: string) => {
    setLoading(true);
    if (assigned_user_id === 'me') {
      assigned_user_id = currentUser?.data?.id;
    }

    try {
      const response = await axiosClient.put(`/admin/tasks/${task.id}`, {
        assigned_user_id: assigned_user_id || null,
      });
      task = response.data;

      queryClient.setQueryData([`admin/manual_calls?`], (oldData: IManualCall[] | undefined) => {
        if (!oldData) return [task];
        return oldData.map((oldTask) => {
          if (oldTask.id === task.id) {
            return {
              ...oldTask,
              assigned_user_id: task.assigned_user_id,
            };
          }
          return oldTask;
        });
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      {isAssignedToMe ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            save('');
          }}
          className="flex items-center space-x-2 transition-all"
          variant="outline"
          title="Unassign this task from me"
          disabled={loading}
        >
          {loading ? (
            <Loader className="w-5 h-5 text-red-500 animate-spin" />
          ) : (
            <XCircle className="w-5 h-5 text-red-500" />
          )}
        </Button>
      ) : (
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            save('me');
          }}
          variant="outline"
          className="flex items-center space-x-2 transition-all"
          title="Claim this task"
          disabled={loading}
        >
          {loading ? (
            <Loader className="w-5 h-5 text-green-500 animate-spin" />
          ) : (
            <UserPlus className="w-5 h-5 text-green-500" />
          )}
        </Button>
      )}

      <Select onValueChange={save} value={task.assigned_user_id || ''} disabled={loading}>
        <SelectTrigger>
          <SelectValue placeholder="Assign To" />
        </SelectTrigger>
        <SelectContent>
          {!isAssignedToMe && <SelectItem value="">Unassign</SelectItem>}
          <SelectSeparator />
          {sortedEmployees.map((employee) => (
            <SelectItem key={employee.user.id} value={employee.user.id}>
              {employee.user.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default TaskAssigment;
