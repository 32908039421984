import React, { useState, useEffect, useCallback } from 'react';
import { Copy } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/utils/utils';
import { Button } from './ui/button';

interface ClickToCopyProps {
  text: string;
  children?: React.ReactNode;
  variant?:
    | 'default'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'ghost'
    | 'link'
    | 'inStock'
    | 'outOfStock'
    | 'invalid'
    | null;
  className?: string;
}

const ClickToCopy: React.FC<ClickToCopyProps> = ({ text, className, children, variant = 'link' }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTooltipOpen(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setIsCopied(false);
      setTooltipOpen(true);
    }
  }, [text]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
        setTooltipOpen(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const copyContent = children || text;

  return (
    <TooltipProvider>
      <Tooltip open={tooltipOpen}>
        <TooltipTrigger asChild>
          <Button variant={variant} className={cn('cursor-pointer', className)} onClick={handleCopy}>
            {copyContent}
            <Copy className="w-4 h-4 mr-1" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{isCopied ? 'Copied!' : 'Click to Copy'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default ClickToCopy;
