import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as z from 'zod';
import Time from '@/components/Time';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormField, FormItem, FormControl, FormMessage } from '@/components/ui/form';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import useNotes from '@/hooks/useNotes';
import axiosClient from '@/utils/axiosClient';

const schema = z.object({
  content: z.string().nonempty({ message: 'Note content is required' }),
});

type FormData = z.infer<typeof schema>;

interface UserNotesProps {
  userId: string;
}

export function UserNotes({ userId }: UserNotesProps) {
  const { data: notes } = useNotes({
    notableType: 'User',
    notableId: userId,
  });

  const queryClient = useQueryClient();
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { content: '' },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await axiosClient.post('/admin/notes', {
        note: {
          content: data.content,
          notable_type: 'User',
          notable_id: userId,
        },
      });

      queryClient.invalidateQueries(['notes', 'User', userId]);
      form.reset();
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Notes</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea {...field} placeholder="Add a note..." className="resize-none" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end">
              <Button type="submit">Add Note</Button>
            </div>
          </form>
        </Form>

        <ScrollArea className="h-[400px]">
          <div className="space-y-4 pr-4">
            {notes?.map((note) => (
              <div key={note.id} className="p-4 rounded-lg border">
                <p className="text-sm">{note.content}</p>
                <div className="mt-2 flex items-center text-xs text-muted-foreground">
                  <span>
                    {note.user.first_name} {note.user.last_name}
                  </span>
                  <span className="mx-2">•</span>
                  <Time>{note.created_at}</Time>
                </div>
              </div>
            ))}
            {!notes?.length && <div className="text-center text-muted-foreground py-4">No notes yet</div>}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
