import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <iframe
        src="https://usudillon.retool.com/apps/In-Progress%20Calls%20Dashboard"
        width="100%"
        height="800px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Dashboard;
