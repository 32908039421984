import { useQuery } from 'react-query';
import { Note, UseNotesParams } from '@/types';
import axiosClient from '@/utils/axiosClient';

const useNotes = ({ notableType, notableId }: UseNotesParams) => {
  return useQuery<Note[]>(['notes', notableType, notableId], async () => {
    const { data } = await axiosClient.get('/admin/notes', {
      params: { notable_type: notableType, notable_id: notableId },
    });
    return data;
  });
};

export default useNotes;
