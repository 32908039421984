import React from 'react';
import { useParams } from 'react-router';
import usePharmacy from '@/hooks/usePharmacy';
import PharmacyForm from './PharmacyForm';

const PharmacyEdit = () => {
  const { id } = useParams<{ id: string }>();
  const pharmacy = usePharmacy(id as string);

  return (
    <div>
      PharmacyEdit
      {pharmacy?.data?.id && <PharmacyForm pharmacy={pharmacy?.data} />}
    </div>
  );
};

export default PharmacyEdit;
