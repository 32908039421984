import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { IMedication, ILocation, ISession, IPharmacy, ICreditTransaction, IRequest } from '@/types';
import { LocationsTable } from './LocationsTable';
import { MedicationsTable } from './MedicationsTable';
import { PharmaciesTable } from './PharmaciesTable';
import { RequestsTable } from './RequestsTable';
import { SessionsTable } from './SessionsTable';
import { TransactionsTable } from './TransactionsTable';
import { UserNotes } from './UserNotes';

interface ProfileTabsProps {
  medications?: IMedication[];
  locations?: ILocation[];
  sessions?: ISession[];
  pharmacies?: IPharmacy[];
  transactions?: ICreditTransaction[];
  requests?: IRequest[];
  onCreditDebit: () => void;
  totalCredits: number;
  userId: string;
}

export function ProfileTabs({
  medications = [],
  locations = [],
  sessions = [],
  pharmacies = [],
  transactions = [],
  requests = [],
  onCreditDebit,
  totalCredits,
  userId,
}: ProfileTabsProps) {
  const sortedRequests = [...requests].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  const sortedMedications = [...medications].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <Tabs defaultValue="notes" className="w-full">
      <TabsList className="grid grid-cols-7 w-full">
        <TabsTrigger value="notes">Notes</TabsTrigger>
        <TabsTrigger value="transactions">Transactions</TabsTrigger>
        <TabsTrigger value="locations">Locations</TabsTrigger>
        <TabsTrigger value="pharmacies">Pharmacies</TabsTrigger>
        <TabsTrigger value="sessions">Sessions</TabsTrigger>
        <TabsTrigger value="medications">Medications</TabsTrigger>
        <TabsTrigger value="requests">Requests</TabsTrigger>
      </TabsList>

      <TabsContent value="medications">
        <MedicationsTable medications={sortedMedications} />
      </TabsContent>

      <TabsContent value="requests">
        <RequestsTable requests={sortedRequests} />
      </TabsContent>

      <TabsContent value="transactions">
        <TransactionsTable transactions={transactions} totalCredits={totalCredits} onCreditDebit={onCreditDebit} />
      </TabsContent>

      <TabsContent value="locations">
        <LocationsTable locations={locations} />
      </TabsContent>

      <TabsContent value="pharmacies">
        <PharmaciesTable pharmacies={pharmacies} />
      </TabsContent>

      <TabsContent value="sessions">
        <SessionsTable sessions={sessions} />
      </TabsContent>

      <TabsContent value="notes">
        <UserNotes userId={userId} />
      </TabsContent>
    </Tabs>
  );
}
