import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Store } from 'lucide-react';
import useAccounts from '@/hooks/useAccounts';
import { IAccount } from '@/types';
import { USDollar } from '@/utils/helpers';
import AccountButton from '../AccountButton';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import Time from '../Time';
import UserButton from '../UserButton';
import { Badge } from '../ui/badge';

const Accounts = () => {
  const model = useAccounts();
  const data = useMemo(() => model.data || [], [model.data]);
  const columns: ColumnDef<IAccount>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Account',
        cell: ({ row }) => <AccountButton account={row.original} />,
      },
      {
        accessorKey: 'provider_type',
        header: 'Provider Type',
        cell: ({ row }) => <Badge>{row.original.provider_type}</Badge>,
      },
      {
        accessorKey: 'provider_pharmacy_cost',
        header: 'Pharmacy Cost',
        cell: ({ row }) => <Badge>{USDollar.format(row.original.provider_pharmacy_cost)}</Badge>,
      },
      {
        accessorKey: 'created_at',
        header: 'Invited',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
      {
        accessorKey: 'owner.name',
        header: 'Owner',
        cell: ({ row }) => <UserButton user={row.original.owner} />,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2 ">
          <Store />
          <div className="text-lg font-bold">Accounts</div>
        </div>
      </div>

      <Section>
        <DataTable data={data} columns={columns} model={model} />
      </Section>
    </>
  );
};

export default Accounts;
