import { IPharmacy } from '@/types.ts';
import { getUrl } from '@/utils/api';

function usePharmacy(id: string) {
  const query = getUrl(`admin/pharmacies/${id}`);
  return {
    ...query,
    data: query.data as IPharmacy,
  };
}

export default usePharmacy;
