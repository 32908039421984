import { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import state from '@/utils/State';
import { Button } from '../ui/button';

interface User {
  access_token: string;
}

const Login = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');

  const [user, setUser] = useState<User | null>(null);

  const setSessionId = useSetRecoilState(state.sessionId);
  const setCurrentUser = useSetRecoilState(state.currentUser);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    const handleGoogleLogin = async () => {
      if (user && user.access_token) {
        try {
          const res = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: 'application/json',
              },
            },
          );

          if (res.data?.email && res.data?.email.endsWith('@findneedle.co')) {
            const response = await axios.post(
              `${import.meta.env.VITE_NEEDLE_API_URL}/v1/sessions/google_sso`,
              res.data,
            );
            console.log(response);

            if (response && response.data && response.data.token && response.data.user) {
              setSessionId(response.data.token);
              setCurrentUser(response.data.user);
              navigate(response.data.user.onboarding_step);

              setTimeout(() => {
                window.location.href = `${response.data.user.onboarding_step}`;
              }, 1000);
            } else {
              setError('Issue with Google SSO login');
            }
          } else {
            setError('Could not find employee with that email address ensure you are using your company email');
          }
        } catch (error) {
          console.log(error);
          setError('Could not find employee with that email address ensure you are using your company email');
        }
      }
    };

    handleGoogleLogin();
  }, [user?.access_token]);

  return (
    <div className="flex flex-col justify-center gap-2 p-6 mx-auto sm:max-w-md">
      <div className="flex flex-col gap-2 mb-6">
        <h1 className="text-4xl font-semibold text-black">Needle CRM</h1>
        <h2 className="text-gray-600 sm:text-lg">Login with your company email</h2>
      </div>

      {error && error.length > 0 && (
        <div className="mb-4">
          <Alert variant="error">
            <AlertCircle className="w-4 h-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </div>
      )}

      <Button variant={'outline'} onClick={() => googleLogin()}>
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.3223 9.20719C18.3223 8.59539 18.2726 7.98029 18.1668 7.37842H9.67773V10.8442H14.539C14.3373 11.9619 13.6891 12.9507 12.74 13.579V15.8278H15.6403C17.3434 14.2603 18.3223 11.9454 18.3223 9.20719Z"
            fill="#4285F4"
          />
          <path
            d="M9.67739 18C12.1047 18 14.1518 17.203 15.6432 15.8273L12.743 13.5786C11.9361 14.1275 10.8944 14.4384 9.6807 14.4384C7.33273 14.4384 5.34191 12.8543 4.6276 10.7246H1.63477V13.0428C3.1626 16.0819 6.27449 18 9.67739 18Z"
            fill="#34A853"
          />
          <path
            d="M4.62464 10.725C4.24764 9.60727 4.24764 8.39691 4.62464 7.27914V4.96094H1.63511C0.358609 7.50402 0.358609 10.5002 1.63511 13.0432L4.62464 10.725Z"
            fill="#FBBC04"
          />
          <path
            d="M9.67934 3.56224C10.9625 3.5424 12.2026 4.02523 13.1318 4.9115L15.7014 2.34196C14.0743 0.814129 11.9149 -0.0258495 9.67934 0.000606499C6.27644 0.000606499 3.16455 1.91867 1.63672 4.96111L4.62625 7.27931C5.33725 5.1463 7.33137 3.56224 9.67934 3.56224Z"
            fill="#EA4335"
          />
        </svg>
        <span className="ml-2 text-slate-800">Sign In With Google</span>
      </Button>
    </div>
  );
};

export default Login;
