import React from 'react';
import NeedlePin from '@/assets/logo-wordmark.svg';
import { IPharmacy } from '@/types';

interface PharmacyIconProps {
  pharmacy: IPharmacy;
}

const PharmacyIcon: React.FC<PharmacyIconProps> = ({ pharmacy }) => {
  if (!pharmacy) {
    return null;
  }

  const getIconUrl = (url: string) => {
    if (url?.includes('logo.dev')) {
      return `${url}?format=png&token=${import.meta.env.VITE_LOGO_DEV_API_KEY}&size=${import.meta.env.VITE_LOGO_DEV_SIZE || 32}`;
    }
    return url;
  };

  let iconUrl: string;

  if (pharmacy.icon_url) {
    iconUrl = getIconUrl(pharmacy.icon_url);
  } else {
    iconUrl = NeedlePin;
  }

  return <img src={iconUrl} alt={pharmacy.name} className="w-8 h-8 rounded-full" />;
};

export default PharmacyIcon;
