import NiceModal from '@ebay/nice-modal-react';
import DrugModal from '@/components/Drugs/DrugModal';
import LocationModal from '@/components/Location/LocationInput.tsx';
import MedicationModal from '@/components/Modals/Medications/MedicationModal.tsx';
import MedicationPhotoModal from '@/components/Modals/Medications/MedicationPhotoModal.tsx';
import PendingTicketModal from '../Admin/Requests/PendingTicketModal';
import TaskDrawer from '../Admin/TaskDrawer/TaskDrawer.tsx';
import CategoryModal from '../Drugs/CategoryModal.tsx';
import PharmacyDrawer from '../Pharmacies/PharmacyDrawer.tsx';
import MedicationResultsModal from './MedicationResults/MedicationResultsModal';

NiceModal.register('pharmacy-drawer', PharmacyDrawer);
NiceModal.register('task-modal', TaskDrawer);
NiceModal.register('medication-modal', MedicationModal);
NiceModal.register('medication-photo-modal', MedicationPhotoModal);
NiceModal.register('location-modal', LocationModal);
NiceModal.register('pending-ticket-modal', PendingTicketModal);
NiceModal.register('medication-results-modal', MedicationResultsModal);
NiceModal.register('drug-modal', DrugModal);
NiceModal.register('category-modal', CategoryModal);

const ModalRegistration = () => {
  return <></>;
};

export default ModalRegistration;
