import { useCallback } from 'react';
import { getUrl } from '@/utils/api';

interface DrugCategory {
  id: string;
  name: string;
}

function useDrugCategories() {
  const { data, refetch: queryRefetch, ...rest } = getUrl('v2/drugs/categories');

  const refetch = useCallback(async () => {
    await queryRefetch();
  }, [queryRefetch]);

  return {
    ...rest,
    refetch,
    data: data as DrugCategory[],
  };
}

export default useDrugCategories;
