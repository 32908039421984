import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { IMedication } from '@/types';

interface MedicationsCompactProps {
  medications?: IMedication[];
}

export function MedicationsCompact({ medications = [] }: MedicationsCompactProps) {
  const sortedMedications = [...medications].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <>
      <CardHeader>
        <CardTitle>Medications ({medications.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[200px]">
          <div className="space-y-4 pr-4">
            {sortedMedications.map((medication) => (
              <div key={medication.id} className="p-3 rounded-lg border">
                <div className="flex items-center justify-between">
                  <span className="font-medium">{medication.medication}</span>
                  <Badge variant={medication.partial_stock_allowed ? 'in_stock' : 'secondary'}>
                    {medication.partial_stock_allowed ? 'Partial OK' : 'No Partial'}
                  </Badge>
                </div>
                <div className="mt-1 text-sm text-muted-foreground">
                  {medication.variant} - {medication.dosage} {medication.dosage_form}
                </div>
                <div className="mt-1 flex items-center justify-between text-xs text-muted-foreground">
                  <span>Qty: {medication.quantity}</span>
                  <Time>{medication.created_at}</Time>
                </div>
              </div>
            ))}
            {medications.length === 0 && <div className="text-center text-muted-foreground py-4">No medications</div>}
          </div>
        </ScrollArea>
      </CardContent>
    </>
  );
}
