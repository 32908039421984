import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/components/ui/sheet';

interface DrawerProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
  modal: any;
  onClose?: () => void;
  wide?: boolean;
}

const Drawer = ({ title, description, children, modal, onClose }: DrawerProps) => {
  if (!modal) {
    console.error('DEV ERROR: The drawer you are trying to use MUST have a modal prop from useModal()');
  }

  const handleClose = () => {
    modal.remove();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Sheet
      open={modal.visible}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
      modal={true}
    >
      <SheetContent
        onPointerDownOutside={() => modal.hide()}
        onEscapeKeyDown={() => modal.hide()}
        className="max-h-screen overflow-y-auto"
      >
        {(title || description) && (
          <SheetHeader>
            {title && <SheetTitle>{title}</SheetTitle>}
            {description && <SheetDescription>{description}</SheetDescription>}
          </SheetHeader>
        )}

        {children}
      </SheetContent>
    </Sheet>
  );
};

export default Drawer;
