import { useState, useRef, useEffect } from 'react';
import { Dot, Hash, SendIcon, User } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Logo from '@/assets/logo-pin.svg';
import Loading from '@/components/Loading';
import Time from '@/components/Time';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import useTextGroups from '@/hooks/useTextGroups';
import useTextMessages from '@/hooks/useTextMessages';
import { useToast } from '@/hooks/useToast';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';
import { Textarea } from '../ui/textarea';

const TextMessaging = () => {
  const { toast } = useToast();
  const { userId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const text_groups = useTextGroups();
  const text_messages = useTextMessages(userId || '');

  const [newMessage, setNewMessage] = useState('');

  const handleGroupSelection = (user_id: string) => {
    navigate(`/messages/${user_id}`);
  };

  const handleNewMessage = () => {
    if (newMessage) {
      axiosClient
        .post('v2/text_messages', {
          body: newMessage,
          user_id: userId,
        })
        .then(() => {
          queryClient.invalidateQueries('v2/text_messages');
          queryClient.invalidateQueries('v2/text_messages/' + userId);
        });

      setNewMessage('');
    }
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Auto-scroll to the latest message when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [text_messages.data]);

  // Function to get the latest message in a group
  const getLatestMessage = (textGroup: {
    text_messages: {
      created_at: string;
      direction: string;
      body: string;
      author?: { id?: string; name?: string };
    }[];
  }) => {
    return textGroup.text_messages.reduce((latest, message) => {
      return new Date(message.created_at) > new Date(latest.created_at) ? message : latest;
    }, textGroup.text_messages[0]);
  };

  // Sort text groups by the latest message date
  const sortedTextGroups = text_groups.data
    ?.map((textGroup) => ({
      ...textGroup,
      latestMessage: getLatestMessage(textGroup),
    }))
    .sort((a, b) => new Date(b.latestMessage.created_at).getTime() - new Date(a.latestMessage.created_at).getTime());

  const selectedGroup = sortedTextGroups?.find((group) => group.user_id === userId);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-1 gap-8 sm:flex-row">
        {/* Left Side */}
        <div className="flex flex-col flex-none w-full sm:w-96">
          <Card className="h-full">
            <CardHeader>
              <CardTitle>Latest Text Messages</CardTitle>
            </CardHeader>
            <CardContent className="px-0 overflow-y-auto">
              <div className="border-t border-t-gray-300">
                {text_groups.isLoading ? (
                  <Loading />
                ) : (
                  sortedTextGroups?.map((textGroup) => {
                    const latestMessage = textGroup.latestMessage;
                    const isUnread = latestMessage.direction === 'inbound';

                    // Determine who was the last messenger
                    let lastMessenger = '';
                    if (latestMessage.direction === 'inbound') {
                      lastMessenger = 'User';
                    } else if (latestMessage.direction === 'outbound') {
                      if (latestMessage.author && latestMessage.author.id) {
                        lastMessenger = 'Us';
                      } else {
                        lastMessenger = 'System';
                      }
                    }

                    return (
                      <div
                        key={textGroup.user_id}
                        onClick={() => handleGroupSelection(textGroup.user_id)}
                        className={cn(
                          'flex flex-col p-2 cursor-pointer border-b border-b-gray-300',
                          userId === textGroup.user_id && 'bg-purple-200',
                          isUnread && 'font-bold',
                        )}
                      >
                        <div className="flex items-center justify-between gap-1">
                          <div className="flex items-center gap-2">
                            <div className="">
                              <Avatar>
                                <AvatarImage src={textGroup?.user?.picture} />
                                <AvatarFallback>{textGroup?.user?.initials}</AvatarFallback>
                              </Avatar>
                            </div>
                            <div className="flex flex-col">
                              <div className="font-semibold line-clamp-1">
                                {textGroup.user.name ? textGroup.user.name : textGroup.user.email}
                              </div>
                              <div className="">
                                <Time className="text-xs">{latestMessage.created_at}</Time>
                              </div>
                            </div>
                          </div>
                          <div>{isUnread && <Dot size={48} className="text-xs text-red-500" />}</div>
                        </div>

                        <div className="">
                          <div className="mt-2 text-xs truncate whitespace-nowrap">{latestMessage.body}</div>
                          <div className="mt-1 text-xs text-gray-500">Last message sent by: {lastMessenger}</div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Side */}
        <div className="flex flex-col flex-1 h-full">
          <Card className="flex flex-col h-full">
            <CardHeader className="p-3 border-b border-b-gray-300">
              <div className="flex items-center justify-between gap-4">
                <CardTitle>
                  <div className="flex items-center gap-4">
                    <div className="">
                      <Avatar>
                        <AvatarImage src={selectedGroup?.user?.picture} />
                        <AvatarFallback>{selectedGroup?.user?.initials}</AvatarFallback>
                      </Avatar>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="font-semibold">{selectedGroup?.user?.name}</div>
                      <div className="text-sm text-gray-500">{selectedGroup?.user?.email}</div>
                    </div>
                  </div>
                </CardTitle>
                <div>
                  {selectedGroup && (
                    <div className="flex items-center gap-4">
                      <Button
                        variant={'outline'}
                        onClick={() => {
                          toast({
                            title: 'Copied',
                            description: 'Email copied to clipboard',
                            duration: 2000,
                          });
                          navigator.clipboard.writeText(selectedGroup.user.email);
                        }}
                      >
                        <Hash size={16} />
                        Copy Email
                      </Button>
                      <Button
                        variant={'outline'}
                        onClick={() => {
                          toast({
                            title: 'Copied',
                            description: 'User ID copied to clipboard',
                            duration: 2000,
                          });
                          navigator.clipboard.writeText(selectedGroup.user.id);
                        }}
                      >
                        <Hash size={16} />
                        Copy UID
                      </Button>
                      <Button asChild>
                        <Link to={`/users/${selectedGroup.user.id}`}>
                          <User size={16} />
                          Profile
                        </Link>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardContent className="flex-1 overflow-y-auto">
              {selectedGroup ? (
                <div className="flex flex-col h-full">
                  <div className="flex-1 p-4">
                    {text_messages.isLoading ? (
                      <Loading />
                    ) : (
                      <div className="flex flex-col gap-4">
                        {text_messages.data?.map((message) => {
                          const author =
                            message.direction === 'inbound'
                              ? message.user.name
                                ? message.user.name
                                : message.user.email
                              : message?.author?.id
                                ? message?.author.name
                                : 'System';

                          return (
                            <div
                              key={message.id}
                              className={cn(
                                'flex',
                                message.direction === 'inbound' ? 'justify-end text-right' : 'justify-start',
                              )}
                            >
                              <div className="max-w-xs p-2">
                                <div
                                  className={cn(
                                    'px-4 py-2 text-sm rounded-lg',
                                    message.direction === 'inbound'
                                      ? 'bg-blue-200 text-right rounded-br-none'
                                      : 'bg-purple-200 rounded-bl-none',
                                  )}
                                >
                                  {message.body}
                                </div>
                                <div
                                  className={cn(
                                    'flex gap-1 mt-1 ',
                                    message.direction === 'inbound' ? 'flex-row-reverse' : 'flex-row',
                                  )}
                                >
                                  {message.direction === 'inbound' ? (
                                    <Avatar>
                                      <AvatarImage src={message?.user?.picture} />
                                      <AvatarFallback>{message?.user?.initials}</AvatarFallback>
                                    </Avatar>
                                  ) : (
                                    <>
                                      {' '}
                                      {message?.author?.id ? (
                                        <Avatar>
                                          <AvatarImage src={message?.author?.picture} />
                                          <AvatarFallback>{message?.author?.initials}</AvatarFallback>
                                        </Avatar>
                                      ) : (
                                        <img src={Logo} className="w-8 h-8" />
                                      )}
                                    </>
                                  )}

                                  <div className="flex flex-col">
                                    <div className="text-xs text-gray-500">{author}</div>
                                    <div className="">
                                      <Time className="text-xs text-gray-500">{message?.created_at}</Time>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div ref={messagesEndRef} />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">Select a user to view messages</div>
              )}
            </CardContent>
            {selectedGroup && (
              <div className="p-4 border-t border-gray-200">
                <div className="flex items-center">
                  <Textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a short text message..."
                    className="flex-1 px-4 py-2 text-sm bg-gray-100 border border-gray-200 rounded-lg focus:outline-none"
                  />
                  <Button className="gap-2 ml-2" onClick={handleNewMessage}>
                    <SendIcon size={16} /> Send
                  </Button>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TextMessaging;
