import { Link } from 'react-router-dom';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { IRequest } from '@/types';

interface RequestsTableProps {
  requests: IRequest[];
}

export function RequestsTable({ requests }: RequestsTableProps) {
  const sortedRequests = [...requests].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Requests ({requests.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[600px]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Location</TableHead>
                <TableHead>Created</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell className="font-medium">
                    <Link to={`/admin/requests/${request.id}`} className="text-blue-600 hover:underline">
                      {request.id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Badge variant={request.status === 'completed' ? 'in_stock' : 'secondary'}>{request.status}</Badge>
                  </TableCell>
                  <TableCell className="whitespace-normal break-words">{request.location?.address}</TableCell>
                  <TableCell>
                    <Time>{request.created_at}</Time>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
