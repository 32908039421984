import { useState, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import useManualCallFilters from '@/hooks/useManualCallFilters';
import { IEmployee } from '@/types';
import { Separator } from '../ui/separator';

type IFilters = {
  selectedCity: string;
  selectedState: string;
  selectedRequest: string;
  selectedMedication: string;
  selectedAssignedTo: string;
  groupByOption: string;
};

const formSchema = z.object({
  selectedCity: z.string().optional().or(z.literal('')),
  selectedState: z.string().optional().or(z.literal('')),
  selectedRequest: z.string().optional().or(z.literal('')),
  selectedMedication: z.string().optional().or(z.literal('')),
  selectedAssignedTo: z.string().optional().or(z.literal('')),
  groupByOption: z.enum([
    'none',
    'medication_name',
    'pharmacy_city_or_zip',
    'pharmacy_state',
    'request_id',
    'assigned_user_id',
  ]),
});

interface FiltersProps {
  setFilters: (filters: IFilters) => void;
  filters: IFilters;
  employees: IEmployee[];
}

const Filters = ({ setFilters, employees }: FiltersProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Initialize React Hook Form with Zod resolver
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: localStorage.getItem('callCenterFilters')
      ? JSON.parse(localStorage.getItem('callCenterFilters') as string)
      : {
          selectedCity: '',
          selectedState: '',
          selectedRequest: '',
          selectedAssignedTo: '',
          selectedMedication: '',
          groupByOption: 'none',
        },
  });

  const { handleSubmit, reset, watch, control, setValue } = form;
  const watchedFilters = watch();

  // Using react-query hook for fetching unique locations
  const {
    data: manualCallsData,
    isLoading: queryLoading,
    error: queryError,
  } = useManualCallFilters({
    medication: watchedFilters.selectedMedication,
    city: watchedFilters.selectedCity,
    state: watchedFilters.selectedState,
    requestId: watchedFilters.selectedRequest,
    assignedTo: watchedFilters.selectedAssignedTo,
  });

  // Memoize sorted filter options to optimize performance
  const sortedUniqueCities = useMemo(() => {
    return [...(manualCallsData?.cities || [])].sort((a, b) => a.localeCompare(b));
  }, [manualCallsData?.cities]);

  const sortedUniqueStates = useMemo(() => {
    return [...(manualCallsData?.states || [])].sort((a, b) => a.localeCompare(b));
  }, [manualCallsData?.states]);

  const sortedUniqueRequests = useMemo(() => {
    return [...(manualCallsData?.request_ids || [])].sort((a, b) => a.localeCompare(b));
  }, [manualCallsData?.request_ids]);

  const sortedUniqueMedications = useMemo(() => {
    return [...(manualCallsData?.medications || [])].sort((a, b) => a.localeCompare(b));
  }, [manualCallsData?.medications]);

  const sortedUniqueEmployees = useMemo(() => {
    return [...(employees || [])].sort((a, b) => a.user.name.localeCompare(b.user.name));
  }, [employees]);

  useEffect(() => {
    if (queryError) setError('Failed to load filter options. Please try again.');
    setIsLoading(queryLoading);
  }, [queryLoading, queryError]);

  useEffect(() => {
    localStorage.setItem('callCenterFilters', JSON.stringify(watchedFilters));
  }, [watchedFilters]);

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setFilters(data as IFilters);
  };

  const handleClearAll = () => {
    const clearedFilters: z.infer<typeof formSchema> = {
      selectedCity: '',
      selectedState: '',
      selectedRequest: '',
      selectedMedication: '',
      selectedAssignedTo: '',
      groupByOption: 'none',
    };
    reset(clearedFilters);
    setFilters(clearedFilters as IFilters);
  };

  return (
    <Form {...form}>
      <form onChange={handleSubmit(onSubmit)} className="mb-4">
        {/* Error Message */}
        {error && <div className="mt-2 text-red-500">{error}</div>}

        {/* Loading Indicator */}
        {isLoading && <div className="mt-2">Loading filters...</div>}

        {/* Advanced Filters */}
        <div id="advanced-filters" className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {/* City Filter */}
          <FormField
            control={control}
            name="selectedCity"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Select {...field} onValueChange={field.onChange} value={field.value} aria-label="Select City">
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select City" />
                    </SelectTrigger>
                    <SelectContent
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                      // Alternatively, using Tailwind CSS classes:
                      // className="max-h-[300px] overflow-y-auto"
                    >
                      <SelectItem value="">All Cities</SelectItem>
                      {sortedUniqueCities.map((city) => (
                        <SelectItem key={city} value={city}>
                          {city}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* State Filter */}
          <FormField
            control={control}
            name="selectedState"
            render={({ field }) => (
              <FormItem>
                <FormLabel>State</FormLabel>
                <FormControl>
                  <Select {...field} onValueChange={field.onChange} value={field.value} aria-label="Select State">
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select State" />
                    </SelectTrigger>
                    <SelectContent
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                      // Alternatively, using Tailwind CSS classes:
                      // className="max-h-[300px] overflow-y-auto"
                    >
                      <SelectItem value="">All States</SelectItem>
                      {sortedUniqueStates.map((state) => (
                        <SelectItem key={state} value={state}>
                          {state}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Request ID Filter */}
          <FormField
            control={control}
            name="selectedRequest"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Request ID</FormLabel>
                <FormControl>
                  <Select {...field} onValueChange={field.onChange} value={field.value} aria-label="Select Request ID">
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select Request" />
                    </SelectTrigger>
                    <SelectContent
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                      // Alternatively, using Tailwind CSS classes:
                      // className="max-h-[300px] overflow-y-auto"
                    >
                      <SelectItem value="">All Requests</SelectItem>
                      {sortedUniqueRequests.map((request) => (
                        <SelectItem key={request} value={request}>
                          {request}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Assigned to Filter */}
          <FormField
            control={control}
            name="selectedAssignedTo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Users</FormLabel>
                <FormControl>
                  <Select {...field} onValueChange={field.onChange} value={field.value} aria-label="Select User">
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select User" />
                    </SelectTrigger>
                    <SelectContent
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                      // Alternatively, using Tailwind CSS classes:
                      // className="max-h-[300px] overflow-y-auto"
                    >
                      <SelectItem value="">All Users</SelectItem>
                      {sortedUniqueEmployees.map((employee) => {
                        return (
                          <SelectItem key={employee.user.id} value={employee.user.id}>
                            {employee.user.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Medication Filter */}
          <FormField
            control={control}
            name="selectedMedication"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Medication</FormLabel>
                <FormControl>
                  <Select {...field} onValueChange={field.onChange} value={field.value} aria-label="Select Medication">
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select Medication" />
                    </SelectTrigger>
                    <SelectContent
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                      // Alternatively, using Tailwind CSS classes:
                      // className="max-h-[300px] overflow-y-auto"
                    >
                      <SelectItem value="">All Medications</SelectItem>
                      {sortedUniqueMedications.map((medication) => (
                        <SelectItem key={medication} value={medication}>
                          {medication}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Group By Option */}
          <FormField
            control={control}
            name="groupByOption"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Group By</FormLabel>
                <FormControl>
                  <Select {...field} onValueChange={field.onChange} value={field.value} aria-label="Group By">
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Group By" />
                    </SelectTrigger>
                    <SelectContent
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                      // Alternatively, using Tailwind CSS classes:
                      // className="max-h-[300px] overflow-y-auto"
                    >
                      <SelectItem value="none">Ungrouped</SelectItem>
                      <SelectItem value="medication_name">Group by Medication</SelectItem>
                      <SelectItem value="pharmacy_city_or_zip">Group by City</SelectItem>
                      <SelectItem value="pharmacy_state">Group by State</SelectItem>
                      <SelectItem value="request_id">Group by Request</SelectItem>
                      <SelectItem value="assigned_user_id">Group by Assigned User</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Active Filters Tags */}
        <div className="flex flex-wrap gap-2 mt-4">
          {Object.entries(watchedFilters).map(([key, value]) => {
            if (value && value !== 'none') {
              const labels: Record<keyof typeof watchedFilters, string> = {
                selectedCity: 'City',
                selectedState: 'State',
                selectedRequest: 'Request ID',
                selectedAssignedTo: 'Assigned To',
                selectedMedication: 'Medication',
                groupByOption: 'Group By',
              };
              return (
                <Button
                  onClick={() => {
                    const newValue = key === 'groupByOption' ? 'none' : '';
                    setValue(key as keyof typeof watchedFilters, newValue);
                    setFilters({
                      ...watchedFilters,
                      [key]: newValue,
                      selectedCity: watchedFilters.selectedCity || '',
                      selectedState: watchedFilters.selectedState || '',
                      selectedRequest: watchedFilters.selectedRequest || '',
                      selectedMedication: watchedFilters.selectedMedication || '',
                      selectedAssignedTo: watchedFilters.selectedAssignedTo || '',
                    });
                  }}
                  key={key}
                >
                  <div className="mr-1 font-semibold">{labels[key as keyof typeof labels]}:</div>
                  <div>{value}</div>
                  <X size={12} className="text-red-500" />
                </Button>
              );
            }
            return null;
          })}
        </div>
        <Separator className="my-4" />
        {/* Action Buttons */}
        <div className="flex flex-wrap gap-4 ">
          <Button type="submit" disabled={isLoading}>
            Apply Filters
          </Button>
          <Button type="button" variant="secondary" onClick={handleClearAll} disabled={isLoading}>
            Clear All
          </Button>
        </div>
        <Separator className="my-4" />
      </form>
    </Form>
  );
};

export default Filters;
