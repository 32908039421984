import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '@/components/Loading';
import { Card } from '@/components/ui/card';
import useUser from '@/hooks/useUser';
import { ChatFeed } from './Chatfeed';
import CreditDebitTool from './CreditDebitTool';
import { CustomerInfo } from './CustomerInfo';
import { MedicationsCompact } from './MedicationsCompact';
import { ProfileHeader } from './ProfileHeader';
import { ProfileTabs } from './ProfileTabs';
import { RequestsCompact } from './RequestsCompact';
import { TransactionsTable } from './TransactionsTable';

const Profile = () => {
  const { id } = useParams();
  const user = useUser(id as string);
  const [isCreditDebitToolOpen, setIsCreditDebitToolOpen] = useState(false);

  if (user.isLoading) {
    return <Loading />;
  }

  const sortedRequests = [...(user.data?.requests || [])].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  const sortedMedications = [...(user.data?.medications || [])].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <div className="container mx-auto p-6 space-y-6">
      {/* Header */}
      <ProfileHeader user={user.data} />

      {/* Main Layout */}
      <div className="grid grid-cols-12 gap-6">
        {/* Left Side - 8 columns */}
        <div className="col-span-8 space-y-6">
          {/* Top Cards - 2x2 Grid */}
          <div className="grid grid-cols-2 gap-6">
            <Card className="h-[300px]">
              <CustomerInfo user={user.data} />
            </Card>
            <Card className="h-[300px]">
              <TransactionsTable
                transactions={user.data?.credit_transactions || []}
                totalCredits={user.data?.total_credits || 0}
                onCreditDebit={() => setIsCreditDebitToolOpen(true)}
                compact
              />
            </Card>
            <Card className="h-[300px]">
              <MedicationsCompact medications={sortedMedications} />
            </Card>
            <Card className="h-[300px]">
              <RequestsCompact requests={sortedRequests} />
            </Card>
          </div>

          {/* Tabs with full tables */}
          <Card>
            <ProfileTabs
              medications={sortedMedications}
              locations={user.data?.locations}
              sessions={user.data?.sessions}
              pharmacies={user.data?.pharmacies}
              transactions={user.data?.credit_transactions}
              requests={sortedRequests}
              onCreditDebit={() => setIsCreditDebitToolOpen(true)}
              totalCredits={user.data?.total_credits}
              userId={id as string}
            />
          </Card>
        </div>

        {/* Right Side - 4 columns */}
        <div className="col-span-4">
          <Card className="h-full">
            <ChatFeed userId={id as string} />
          </Card>
        </div>
      </div>

      {/* Credit/Debit Modal */}
      {isCreditDebitToolOpen && (
        <CreditDebitTool
          userId={id as string}
          onClose={() => {
            setIsCreditDebitToolOpen(false);
            user.refetch();
          }}
        />
      )}
    </div>
  );
};

export default Profile;
