import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const List = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/calls');
  }, []);

  return null;
};

export default List;
