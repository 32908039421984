import { User } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { IUser } from '@/types';
import ClickToCopy from './ClickToCopy';
import { Badge } from './ui/badge';
import { Button } from './ui/button';

interface UserButtonProps {
  user: IUser;
  name?: boolean;
  id?: boolean;
}

const UserButton = ({ user, name, id }: UserButtonProps) => {
  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Button size={'sm'} variant={'outline'} asChild className="text-sm">
          <Link to={`/users/${user.id}`} target="_blank" className="text-sm">
            <User />
            {id ? user.id : <>{name ? user.name : user.email}</>}

            {user.plan && <Badge className="px-1 min-w-10 justify-center py-1 text-[10px]">{user.plan}</Badge>}
          </Link>
        </Button>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuLabel>User Options</ContextMenuLabel>
        <ContextMenuItem asChild>
          <ClickToCopy text={user.id} />
        </ContextMenuItem>
        <ContextMenuItem asChild>
          <ClickToCopy text={user.email} />
        </ContextMenuItem>
        {user.name && (
          <ContextMenuItem asChild>
            <ClickToCopy text={user.name} />
          </ContextMenuItem>
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default UserButton;
