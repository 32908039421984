import { useModal } from '@ebay/nice-modal-react';
import { Building } from 'lucide-react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { IPharmacy } from '@/types';
import ClickToCopy from './ClickToCopy';
import { Button } from './ui/button';

interface Props {
  pharmacy: IPharmacy;
}

const PharmacyButton = ({ pharmacy }: Props) => {
  const drawer = useModal('pharmacy-drawer');
  return (
    <div>
      <ContextMenu>
        <ContextMenuTrigger>
          <Button
            size={'sm'}
            variant={'outline'}
            onClick={(event) => {
              event.stopPropagation();
              drawer.show({ pharmacyId: pharmacy.id });
            }}
            className="text-left truncate w-44"
            title={pharmacy.name}
          >
            <Building />
            {pharmacy.name}
          </Button>
        </ContextMenuTrigger>
        <ContextMenuContent>
          <ContextMenuLabel>Pharmacy Options</ContextMenuLabel>
          <ContextMenuItem asChild>
            <ClickToCopy text={pharmacy.id} />
          </ContextMenuItem>
          <ContextMenuItem asChild>
            <ClickToCopy text={pharmacy.name} />
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
    </div>
  );
};

export default PharmacyButton;
